import { Col, Container, Row } from "react-bootstrap";
import LoadingSpinner from "../Loader/LoadingSpinner";

export default function Initializing() {
    return (
        <Container>
            <Row>
                <Col>
                    {/* <h1>Initializing...</h1> */}
                    {/* <p>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Initializing...</span>
                    </p> */}
                    <LoadingSpinner text={'Initializing...'} />
                </Col>
            </Row>
        </Container>
    )
}