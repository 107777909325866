import { useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router"
import { BookingEntry } from "../../../Components/Bookings/BookingEntry/BookingEntry"
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook"
import { useAppDispatch } from "../../../redux/store"
import BookingGuestEntry from "../../../Components/BookingGuests/BookingGuestEntry/BookingGuestEntry"
import PurchaseEntry from "../../../Components/Purchases/PurchaseEntry"
import { getChildProperties } from "../../../Helpers/Property/multiUnit"
import canAccess from "../../../services/accessService/accessService"
import AccessClickButton from "../../../Components/Buttons/AccessClickButton"
import BookingModal, { useBookingModal } from "../../../Components/Bookings/BookingModal/BookingModal"
import { FRONTEND_URL } from "../../../Config/config"
import { convertStringToJSON } from "../../../Helpers/Strings/stringHelpers"
import { ConfirmModal } from "../../../Components/Modals/confirmModal"
import { showSuccess } from "../../../redux/counter/counterSlice"
import { useAddNewDateBlockMutation, useEditBookingMutation, useGetBookingByIdQuery, useGetBookingGuestsQuery, useGetPurchasesQuery, useSendBookingConfirmedEmailMutation } from "../../../services/bClientApi"
import { airBnbReservationDetailsLink } from "../../../Helpers/Booking/airbnbReservationDetails"

export default function BookingPage() {
  const [addNewDateBlock] = useAddNewDateBlockMutation()

  const [
    sendBookingConfirmedEmail,
    {
      error: sendBookingConfirmedEmailError,
      isLoading: sendBookingConfirmedEmailLoading,
    }
  ] = useSendBookingConfirmedEmailMutation()

  const { myProperties } = useMyProperties()
  const { bookingId = '' } = useParams()

  const {
    data,
    // isLoading,
    error,
  } = useGetBookingByIdQuery(bookingId)
  const bookingData = data || {}

  const [editBooking] = useEditBookingMutation()


  const dispatch = useAppDispatch()

  const [sendBookingEmailModal, setSendBookingEmailModal] = useState(false)

  const propertyData = myProperties[bookingData?.propertyId] || {}

  const {
    data: bookingGuestData,
  } = useGetBookingGuestsQuery({
    bookingGuestsQuery: {
      bookingId: bookingId,
    },
    pagination: {
      page: 1,
      limit: 1,
    }
  }, {
    skip: !bookingId
  })

  const bookingGuestsList = bookingGuestData?.docs || []

  const bookingGuestInfo = bookingGuestsList && bookingGuestsList[0] && bookingGuestsList[0].bookingId === bookingId ? bookingGuestsList[0] : null

  const bookingGuestLink = `${FRONTEND_URL}/guest/confirm-booking?sourceConfirmation=${bookingData.sourceConfirmation}`
  const bookingDetailsLink = `${FRONTEND_URL}/booking-details/${bookingId}`

  const purchaseRefId = bookingData?.sourceConfirmation

  const {
    data: purchaseData,
    // isLoading: purchasesLoading,
    // error: purchasesError,
  } = useGetPurchasesQuery({
    purchasesQuery: {
      // productId: bookingId
      referenceNumber: purchaseRefId,
      sessionStatus: 'complete',
    },
    pagination: {
      limit: 100,
      page: 1,
    }
  }, {
    skip: !purchaseRefId
  })
  const purchasesList = purchaseData?.docs || []

  let purchaseInfo: any[] = []

  if (purchasesList && purchasesList[0] && purchasesList[0].products && purchasesList[0].products.length > 0) {
    purchaseInfo = purchasesList
  }

  const {
    editBookingModal,
    clearBookingModal,
    onEditClick,
    onCheckOutClick,
    bookingModalonSubmit
  } = useBookingModal()


  let emailAddress = ''

  if (purchaseInfo && purchaseInfo[0] && purchaseInfo[0].email) {
    emailAddress = purchaseInfo[0].email
  }
  if (!emailAddress && bookingData?.contactInfo?.email) {
    emailAddress = bookingData.contactInfo.email
  }


  const hasChildren = getChildProperties(myProperties, bookingData?.propertyId).length > 0
  const isFontaine = propertyData?.title?.toLowerCase().indexOf('fontaine') > -1
  let usingBlocks = !hasChildren && isFontaine
  if (usingBlocks && propertyData?.linkedServices) {
    propertyData.linkedServices.forEach((service: any) => {
      const {
        linkedService,
        linkedServiceId,
      } = service

      if (linkedService === 'fontainePortal') {
        try {
          const fontainePortalCreds = JSON.parse(linkedServiceId)
          const {
            skipValidation
          } = fontainePortalCreds
          if (skipValidation) {
            usingBlocks = false
          }
        } catch (error) { }
      }
    })
  }

  const cancelAndBlock = async () => {
    console.log('cancel and block')
    const newBlock = {
      created: new Date(),
      checkIn: bookingData.checkIn,
      checkOut: bookingData.checkOut,
      firstName: bookingData.contactInfo.firstName,
      lastName: bookingData.contactInfo.lastName,
      propertyId: bookingData.propertyId,
      confirmationCode: bookingData.confirmationCode,
      source: 'owner',
      status: 'confirmed',
      tags: []
    }
    console.log('current booking', bookingData)
    const updatedBooking = await editBooking({
      bookingId: bookingData._id,
      bookingInfo: {
        bookingStatus: 'canceled'
      }
    }).unwrap()
    console.log('updatedBooking', updatedBooking)

    console.log('newBlock', newBlock)
    const newDateBlock = await addNewDateBlock(newBlock).unwrap()
    console.log('newDateBlock', newDateBlock)
  }

  const beforeCheckout = bookingData && bookingData.checkOut && new Date(bookingData.checkOut) > new Date()
  const afterCheckin = bookingData && bookingData.checkIn && new Date(bookingData.checkIn) < new Date()
  const checkedIn = beforeCheckout && afterCheckin

  let remoteLockPayload: {
    userId?: string,
    pin?: string,
    checkIn?: string,
    checkOut?: string,
  } = {}
  if (bookingData && bookingData.linkedServices && bookingData.linkedServices.length > 0) {
    bookingData.linkedServices.forEach((service: any) => {
      if (service.linkedService === 'remoteLock') {
        remoteLockPayload = convertStringToJSON(service.linkedServiceId)
      }
    })
  }

  const airbnbReservationDetails = airBnbReservationDetailsLink(bookingData)
  
  return (
    <Container>
      <Row>
        <Col>
          <h1>Booking Information</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {sendBookingConfirmedEmailError && (<>{JSON.stringify(sendBookingConfirmedEmailError)}<br /></>)}

          {bookingId && bookingData && (
            <div className="booking-info pb-4">
              <BookingEntry
                bookingInfo={bookingData}
                propertyName={propertyData?.title}
                options={{
                  details: true,
                  disableLinks: {
                    view: true,
                    delete: true,
                  }
                }}
                extraButtons={[
                  ...(
                    canAccess('routes', 'edit-booking') ?
                      [
                        {
                          label: 'Edit🕒',
                          variant: 'primary',
                          onClick: () => onEditClick(bookingData)
                        },
                        ...(checkedIn ? [{
                          label: 'Check Out⚡',
                          variant: 'secondary',
                          onClick: () => {
                            onCheckOutClick(bookingData)
                          }
                        }] : [])
                      ] : []
                  ),

                  ...(
                    beforeCheckout &&
                      canAccess('routes', 'edit-booking') &&
                      canAccess('routes', 'add-date-block') &&
                      bookingData.bookingStatus === 'confirmed' &&
                      bookingData.confirmationCode &&
                      usingBlocks ? [
                      {
                        label: 'Cancel and Block',
                        variant: 'warning',
                        onClick: cancelAndBlock,
                      }
                    ] : [])
                ]}
              />

              {remoteLockPayload.userId && remoteLockPayload.pin && (
                <>
                  <h3>Remote Lock Information</h3>
                  <p>
                    <b>Remote Lock User Id:</b> <a href={`https://connect.remotelock.com/access/user/${remoteLockPayload.userId}`} target="_blank" rel="noreferrer">{remoteLockPayload.userId}</a><br />
                    <b>Remote Lock Pin:</b> {remoteLockPayload.pin}<br />
                  </p>
                </>
              )}


              {bookingData && bookingData.notes && (
                <>
                  <h3>Booking Notes</h3>
                  <p className="keep-whitespace">
                    {bookingData.notes}
                  </p>
                  <br />
                </>
              )}

              {bookingData.source === 'direct' || bookingData.source === 'website' ? (
                <>
                  <h3>Booking Related Functions</h3>
                  {emailAddress && (
                    <>
                      <b>Email Address:</b> {emailAddress}<br />
                      <Button
                        className={"mb-2"}
                        variant="primary"
                        disabled={sendBookingConfirmedEmailLoading}
                        onClick={async () => {
                          setSendBookingEmailModal(true)
                        }}
                      >
                        Send Booking Confirmation Email
                      </Button>
                      <br />

                      <ConfirmModal
                        title="Send Booking Confirmation Email"
                        message="Are you sure you want to send the confirmation email?"
                        active={sendBookingEmailModal}
                        onClose={() => setSendBookingEmailModal(false)}
                        onConfirm={async () => {
                          setSendBookingEmailModal(false)
                          console.log('attempting to send confirmation email')
                          const mailInfo = await sendBookingConfirmedEmail(bookingId).unwrap()
                          console.log('mailInfo', mailInfo)
                          dispatch(showSuccess('Email Sent'))
                        }}
                      />


                    </>
                  )}

                  {!emailAddress && (
                    <>
                      <b>Booking does not have an email address.</b>
                      <br />
                      <br />
                    </>
                  )}
                </>
              ) : null}

              {canAccess('routes', 'booking-guest') && (
                <>
                  <h3>Booking Guest Link</h3>
                  <a href={bookingGuestLink} target="_blank" rel="noreferrer" className="mb-2">
                    {bookingGuestLink}
                  </a>
                  <br /> <br />
                  {canAccess('routes', 'edit-booking') && bookingGuestInfo && !bookingData.bookingGuestInfoId && (
                    <>
                      <AccessClickButton
                        variant="primary"
                        text="Link Guest Info"
                        resource="routes"
                        action="edit-booking"
                        onClick={() => {
                          editBooking({
                            bookingId: bookingData._id,
                            bookingInfo: {
                              bookingGuestInfoId: bookingGuestInfo._id,
                            }
                          })
                        }}
                      />
                    </>
                  )}
                </>
              )}

              {canAccess('booking', 'airbnbReservationDetails') && airbnbReservationDetails && (
                <>
                  <h3>Airbnb Reservation Details</h3>
                  <a href={airbnbReservationDetails} target="_blank" rel="noreferrer" className="mb-2">
                    {airbnbReservationDetails}
                  </a>
                  <br /> <br />
                </>
              )}

            </div>
          )}


          {canAccess('routes', 'booking-guest') && bookingGuestInfo && (
            <>
              <h3>Booking Details Page</h3>
              <a href={bookingDetailsLink} target="_blank" rel="noreferrer" className="mb-2">
                {bookingDetailsLink}
              </a>

              <div className="booking-guest-info">
                <h3>Guest Info</h3>
                <BookingGuestEntry
                  bookingGuestInfo={bookingGuestInfo}
                  propertyData={propertyData}
                />
              </div>
            </>
          )}

          {canAccess('routes', 'purchase') && purchaseInfo && purchaseInfo.length > 0 && (
            <>
              <h3>Purchase Info</h3>
              {purchaseInfo.map((purchase) => (
                <PurchaseEntry
                  key={purchase._id}
                  purchaseData={purchase}
                />
              ))}
            </>
          )}
        </Col>
      </Row>

      <BookingModal
        active={editBookingModal.active}
        booking={editBookingModal.booking}
        clearBookingModal={clearBookingModal}
        bookingModalonSubmit={bookingModalonSubmit}
      />
    </Container>
  )
}
