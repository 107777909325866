import { Button, Card, Col, Container, Row } from "react-bootstrap";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import timeService, { DATE_FORMAT } from "../../../services/time/timeService";
import { useMemo, useState } from "react";
import QuickForm from "../../../Components/Forms/QuickForm/QuickForm";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import canAccess from "../../../services/accessService/accessService";
import AccessClickButton from "../../../Components/Buttons/AccessClickButton";
import { PropertyBookingEntry } from "../../../Types/bookingTypes";
import { BookingGuestEntryType } from "../../../Types/bookingGuestTypes";
import { FieldGeneratorFieldType } from "../../../Components/FormFields/FieldGenerator/FieldGenerator";
import { ConfirmModal } from "../../../Components/Modals/confirmModal";
import { useEditBookingGuestMutation, useGetBookingGuestsQuery, useGetBookingsQuery, useSendBookingGuestEmailMutation } from "../../../services/bClientApi";
import { FRONTEND_URL } from "../../../Config/config";
import AccessLinkButton from "../../../Components/Buttons/AccessLinkButton";
import { showSuccess } from "../../../redux/counter/counterSlice";
import { useAppDispatch } from "../../../redux/store";
import { airBnbReservationDetailsLink } from "../../../Helpers/Booking/airbnbReservationDetails";
import { BoxArrowUpRight } from "react-bootstrap-icons";
import { bookingSourceIcons } from "../../../Components/Bookings/BookingEntry/BookingEntry";

export default function BookingGuestConfirmationPage() {

    const dispatch = useAppDispatch()

    const [editBookingGuest] = useEditBookingGuestMutation()

    const [sendBookingGuestEmail, { isLoading: sendBookingGuestEmailLoading, }] = useSendBookingGuestEmailMutation()

    const todayString = timeService.getCurrentTime().toFormat(DATE_FORMAT)

    const [mode, setMode] = useState<'all' | 'needsInfo' | 'pendingSend' | 'pendingConfirm' | 'confirmed'>('all')

    const [activeConfirmationModal, setActiveConfirmationModal] = useState({
        active: false,
        title: '',
        message: '',
        onConfirm: () => { },
        onClose: () => { }
    })

    const resetConfirmationModal = () => {
        setActiveConfirmationModal({
            active: false,
            title: '',
            message: '',
            onConfirm: () => { },
            onClose: () => { }
        })
    }

    const { myProperties, publicPropertyIdKeys } = useMyProperties()
    const propertyOptions = useMyPropertiesSelectValues(myProperties)

    // START BOOKING QUERY STUFF
    const startOfToday = timeService.getCurrentTime().startOf('day').toMillis()
    const queryEndDate = timeService.getCurrentTime().plus({ weeks: 2 }).startOf('day').toMillis()
    const [
        activeBookingQuery,
        setActiveBookingQuery
    ] = useState<any>({
        query: {
            checkIn: startOfToday,
            checkOut: queryEndDate,
            bookingStatus: ['new', 'pending', 'confirmed'],
            requiresBookingGuestInfo: true,
            // bookingGuestInfoId: ['', null],
        },
        limit: 500,
        page: 1
    })

    const {
        data: bookingData,
        isLoading: bookingsLoading,
        // error,
        // requestId
    } = useGetBookingsQuery({
        bookingsQuery: {
            ...activeBookingQuery.query,
            propertyId: publicPropertyIdKeys
        },
        pagination: {
            limit: activeBookingQuery.limit,
            page: activeBookingQuery.page
        }
    }, { skip: !activeBookingQuery.query.checkIn || publicPropertyIdKeys.length === 0 })
    // END BOOKING QUERY STUFF

    // START BOOKING GUEST INFO QUERY STUFF
    const [
        activeBookingGuestInfoQuery,
        setActiveBookingGuestInfoQuery
    ] = useState<any>({
        query: {
            // status: ['new', 'pending'],
            // status: 'confirmed',
            status: ['new', 'pending', 'confirmed'],
            arrivalTimeStart: startOfToday,
        },
        limit: 500,
        page: 1
    })

    const {
        data: bookingGuestsData,
        isLoading: bookingGuestsListLoading,

    } = useGetBookingGuestsQuery({
        bookingGuestsQuery: {
            ...activeBookingGuestInfoQuery.query,
            propertyId: publicPropertyIdKeys,
        },
        pagination: {
            limit: activeBookingGuestInfoQuery.limit,
            page: activeBookingGuestInfoQuery.page
        }
    }, { skip: !activeBookingGuestInfoQuery.query.arrivalTimeStart || publicPropertyIdKeys.length === 0 })


    // END BOOKING GUEST INFO QUERY STUFF

    const bookingGuestsByBookingId: any = {}
    if (bookingGuestsData?.docs?.length > 0) {
        bookingGuestsData.docs.forEach((bookingGuestInfo: any) => {
            if (bookingGuestInfo.bookingId) {
                bookingGuestsByBookingId[bookingGuestInfo.bookingId] = bookingGuestInfo
            }
        })
    }

    const changeMode = (newMode: 'all' | 'needsInfo' | 'pendingSend' | 'pendingConfirm' | 'confirmed') => {
        if (newMode === mode) {
            return
        }
        setMode(newMode)
    }

    const updateActiveBookingQuery = (queryInfo: any) => {
        setActiveBookingQuery({
            ...activeBookingQuery,
            query: {
                ...activeBookingQuery.query,
                ...queryInfo
            }
        })
    }

    const updateActiveBookingGuestInfoQuery = (queryInfo: any) => {
        setActiveBookingGuestInfoQuery({
            ...activeBookingGuestInfoQuery,
            query: {
                ...activeBookingGuestInfoQuery.query,
                ...queryInfo
            }
        })
    }

    const handleFormSubmit = (values: any) => {
        const newBookingQuery: any = {}
        const newGuestInfoQuery: any = {}

        newBookingQuery.propertyId = values.propertyId
        newGuestInfoQuery.propertyId = values.propertyId

        // handle checkIn and checkOut
        newBookingQuery.checkIn = timeService.dateToMillis(values.checkIn)
        newBookingQuery.checkOut = timeService.dateToMillis(values.checkOut)

        newGuestInfoQuery.arrivalTimeStart = timeService.dateToMillis(values.checkIn)

        newBookingQuery.firstName = values.firstName
        newBookingQuery.lastName = values.lastName

        newGuestInfoQuery.firstName = values.firstName
        newGuestInfoQuery.lastName = values.lastName

        updateActiveBookingQuery(newBookingQuery)
        updateActiveBookingGuestInfoQuery(newGuestInfoQuery)
    }

    const dataByDay = useMemo(() => {

        const bookingsList = bookingData?.docs || []
        const bookingGuestsList = bookingGuestsData?.docs || []

        const dataByDayList: any[] = []

        const dateRangeHash = timeService.createDateHashObject(
            activeBookingQuery.query.checkIn,
            activeBookingQuery.query.checkOut
        )

        if (!bookingsLoading && bookingsList) {
            bookingsList.forEach((bookingInfo: PropertyBookingEntry) => {
                const formattedCheckIn = timeService.getFormattedTime(bookingInfo.checkIn, DATE_FORMAT)
                if (!dateRangeHash[formattedCheckIn]) {
                    return
                }
                if (!dateRangeHash[formattedCheckIn].bookings) {
                    dateRangeHash[formattedCheckIn].bookings = []
                }
                dateRangeHash[formattedCheckIn].bookings.push(bookingInfo)
            })
        }

        if (!bookingGuestsListLoading && bookingGuestsList) {
            bookingGuestsList.forEach((bookingGuestInfo: BookingGuestEntryType) => {
                const formattedCheckIn = timeService.getFormattedTime(bookingGuestInfo.arrivalTime, DATE_FORMAT)
                // remove if property does not require bookingGuestInfo
                if (myProperties[bookingGuestInfo.propertyId] && !myProperties[bookingGuestInfo.propertyId].services?.bookingGuestInfo) {
                    return
                }
                if (!dateRangeHash[formattedCheckIn]) {
                    return
                }
                if (!dateRangeHash[formattedCheckIn].bookingGuests) {
                    dateRangeHash[formattedCheckIn].bookingGuests = []
                }
                dateRangeHash[formattedCheckIn].bookingGuests.push(bookingGuestInfo)
            })
        }

        Object.keys(dateRangeHash).forEach((dateKey: string) => {
            dataByDayList.push(dateRangeHash[dateKey])
        })

        return dataByDayList
    }, [bookingGuestsData, bookingGuestsListLoading, bookingData, bookingsLoading, activeBookingQuery.query.checkIn, activeBookingQuery.query.checkOut, myProperties])

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Booking Guest Confirmation</h1>
                    <hr />

                    <QuickForm
                        // key={currentRequestId}
                        initialData={{
                            // name: activeQuery.query.name,
                            propertyId: activeBookingQuery.query.propertyId,
                            dateRange: null,
                            checkIn: activeBookingQuery.query.checkIn,
                            checkOut: activeBookingQuery.query.checkOut,
                        }}
                        fields={bookingGuestSearchFields(propertyOptions)}
                        disabled={bookingsLoading || bookingGuestsListLoading}
                        onSubmit={handleFormSubmit}
                    />

                    <hr />

                    <div>

                        {/* All button */}

                        <AccessClickButton
                            variant={mode === 'all' ? 'primary' : 'secondary'}
                            onClick={() => changeMode('all')}
                            className="mb-2 me-2"
                            text={"All"}
                            action={'all'}
                            resource={'bookingGuest'}
                        />


                        <AccessClickButton
                            variant={mode === 'needsInfo' ? 'primary' : 'secondary'}
                            onClick={() => changeMode('needsInfo')}
                            className="mb-2 me-2"
                            text={"Needs Info"}
                            action={'needsInfo'}
                            resource={'bookingGuest'}
                        />


                        <AccessClickButton
                            variant={mode === 'pendingSend' ? 'primary' : 'secondary'}
                            onClick={() => {
                                changeMode('pendingSend')
                            }}
                            className="mb-2 me-2"
                            text={"Pending Send"}
                            action={'pendingSend'}
                            resource={'bookingGuest'}
                        />

                        <AccessClickButton
                            variant={mode === 'pendingConfirm' ? 'primary' : 'secondary'}
                            onClick={() => {
                                changeMode('pendingConfirm')
                            }}
                            className="mb-2 me-2"
                            text={"Pending Confirm"}
                            action={'pendingConfirm'}
                            resource={'bookingGuest'}
                        />

                        <AccessClickButton
                            variant={mode === 'confirmed' ? 'primary' : 'secondary'}
                            onClick={() => {
                                changeMode('confirmed')
                            }}
                            className="mb-2 me-2"
                            text={"Confirmed"}
                            action={'confirmed'}
                            resource={'bookingGuest'}
                        />
                    </div>
                    <hr />


                    {!bookingsLoading && bookingData?.docs?.length > 0 && (
                        <div>
                            {dataByDay.map((dayData: any, idx: number) => {
                                if (!dayData.bookings) return null
                                return (
                                    <div className="" key={`booking-day-${idx}-${dayData.timeStamp}`}>
                                        <h3 style={stickyStyle}>
                                            <b>{dayData.date === todayString ? 'TODAY' : ''}</b> {timeService.getFormattedTime(dayData.timeStamp, 'LL/dd/yyyy cccc')}
                                        </h3>
                                        <hr />
                                        {dayData.bookings && dayData.bookings.map((bookingEntry: any, idx: number) => {

                                            const extraButtons = []

                                            let bookingGuestEntry: any = {}
                                            let needsInfo = true

                                            let colorBg = '#ffe9ec' // red

                                            if (bookingGuestsByBookingId[bookingEntry._id]) {
                                                bookingGuestEntry = bookingGuestsByBookingId[bookingEntry._id]
                                                needsInfo = false
                                                colorBg = '#faf8cf' // yellow

                                                if (bookingGuestEntry.status === 'pending') {
                                                    colorBg = '#fff' // white
                                                }

                                                if (bookingGuestEntry.status === 'confirmed') {
                                                    colorBg = '#e8fbe8' // green
                                                }

                                                if (bookingGuestEntry.status === 'new' && canAccess('bookingGuest', 'markPending')) {
                                                    extraButtons.push({
                                                        label: 'Mark Pending',
                                                        disabled: sendBookingGuestEmailLoading ? true : false,
                                                        variant: 'secondary',
                                                        onClick: async () => {
                                                            setActiveConfirmationModal({
                                                                active: true,
                                                                title: 'Mark Pending',
                                                                message: 'Are you sure you want to mark this guest as pending?',
                                                                onConfirm: async () => {
                                                                    const updatedBookingGuest = await editBookingGuest({
                                                                        bookingGuestId: bookingGuestEntry._id,
                                                                        bookingGuestInfo: {
                                                                            status: 'pending'
                                                                        }
                                                                    }).unwrap()
                                                                    console.log('updatedBookingGuest', updatedBookingGuest)
                                                                    dispatch(showSuccess('Guest marked as pending'))
                                                                },
                                                                onClose: resetConfirmationModal
                                                            })
                                                        }
                                                    })
                                                }

                                                if (canAccess('bookingGuest', 'sendConfirm')) {
                                                    extraButtons.push({
                                                        label: `${bookingGuestEntry.status !== 'new' ? 'Resend ' : 'Send '}✉️`,
                                                        // label: `${bookingGuestEntry.status !== 'new' ? 'Resend ' : ''}✉️ the 🦃`,
                                                        disabled: sendBookingGuestEmailLoading ? true : false,
                                                        variant: 'primary',
                                                        onClick: async () => {
                                                            setActiveConfirmationModal({
                                                                active: true,
                                                                title: `${bookingGuestEntry.status !== 'new' ? 'Resend' : 'Send'} Confirmation Email`,
                                                                message: `Are you sure you want to ${bookingGuestEntry.status !== 'new' ? 'resend' : 'send'} the confirmation email?`,
                                                                onConfirm: async () => {
                                                                    const mailInfo = await sendBookingGuestEmail(
                                                                        bookingGuestEntry._id
                                                                    ).unwrap()
                                                                    console.log('mailInfo', mailInfo)
                                                                    dispatch(showSuccess('Email sent'))
                                                                },
                                                                onClose: resetConfirmationModal
                                                            })
                                                        }
                                                    })
                                                }

                                                if (bookingGuestEntry.status === 'pending' && canAccess('bookingGuest', 'markConfirm')) {
                                                    extraButtons.push({
                                                        label: 'Confirm Guest',
                                                        disabled: sendBookingGuestEmailLoading ? true : false,
                                                        variant: 'success',
                                                        onClick: async () => {
                                                            setActiveConfirmationModal({
                                                                active: true,
                                                                title: 'Confirm Guest',
                                                                message: 'Are you sure you want to confirm this guest?',
                                                                onConfirm: async () => {
                                                                    const updatedBookingGuest = await editBookingGuest({
                                                                        bookingGuestId: bookingGuestEntry._id,
                                                                        bookingGuestInfo: {
                                                                            status: 'confirmed'
                                                                        }
                                                                    }).unwrap()
                                                                    console.log('updatedBookingGuest', updatedBookingGuest)
                                                                },
                                                                onClose: resetConfirmationModal
                                                            })
                                                        }
                                                    })
                                                }

                                                if (bookingGuestEntry.status === 'pending' && canAccess('bookingGuest', 'markDenied')) {
                                                    extraButtons.push({
                                                        label: 'Deny Guest',
                                                        disabled: sendBookingGuestEmailLoading ? true : false,
                                                        variant: 'danger',
                                                        onClick: async () => {
                                                            setActiveConfirmationModal({
                                                                active: true,
                                                                title: 'Deny Guest',
                                                                message: 'Are you sure you want to deny this guest?',
                                                                onConfirm: async () => {
                                                                    const updatedBookingGuest = await editBookingGuest({
                                                                        bookingGuestId: bookingGuestEntry._id,
                                                                        bookingGuestInfo: {
                                                                            status: 'new'
                                                                        }
                                                                    }).unwrap()
                                                                    console.log('updatedBookingGuest', updatedBookingGuest)
                                                                },
                                                                onClose: resetConfirmationModal
                                                            })
                                                        }
                                                    })
                                                }

                                            }
                                            let guestName = `${bookingEntry?.contactInfo?.firstName} ${bookingEntry?.contactInfo?.lastName}`
                                            const propertyName = myProperties[bookingEntry.propertyId]?.title
                                            const guestPhone = bookingEntry?.contactInfo?.phone

                                            const bookingDetailsLink = `${FRONTEND_URL}/booking-details/${bookingEntry._id}`

                                            const airbnbReservationDetails = airBnbReservationDetailsLink(bookingEntry)


                                            if (mode === 'needsInfo' && !needsInfo) {
                                                return null
                                            }

                                            if (mode === 'pendingSend' && bookingGuestEntry.status !== 'new') {
                                                return null
                                            }

                                            if (mode === 'pendingConfirm' && bookingGuestEntry.status !== 'pending') {
                                                return null
                                            }

                                            if (mode === 'confirmed' && bookingGuestEntry.status !== 'confirmed') {
                                                return null
                                            }

                                            return (
                                                <Card
                                                    key={`booking-entry-${idx}-${bookingEntry._id}`}
                                                    className="mb-3"
                                                    style={{
                                                        backgroundColor: colorBg,
                                                    }}
                                                >
                                                    <Card.Body>

                                                        {needsInfo && (
                                                            <>
                                                                <Card.Title>
                                                                    {guestName}
                                                                </Card.Title>
                                                                <Card.Subtitle className="mb-2">Check In: {timeService.getFormattedTime(bookingEntry.checkIn)}</Card.Subtitle>
                                                                <b>Property:</b> {propertyName} <br />
                                                                {bookingEntry?.contactInfo?.email && bookingEntry.contactInfo.email.indexOf('airbnb.com') === -1 && (<><b>Email:</b> {bookingEntry?.contactInfo?.email} <br /></>)}
                                                                {guestPhone && (<><b>Phone:</b> {guestPhone} <br /></>)}
                                                                {canAccess('booking', 'airbnbReservationDetails') && (
                                                                    <>
                                                                        <b>Source:</b> {bookingEntry?.source}
                                                                        {bookingEntry?.source && typeof bookingEntry?.source === 'string' && bookingSourceIcons[bookingEntry?.source] && (
                                                                            <>
                                                                                <img src={bookingSourceIcons[bookingEntry?.source]} alt={bookingEntry?.source} className="booking-source-icon" />
                                                                                {canAccess('booking', 'airbnbReservationDetails') && airbnbReservationDetails && (
                                                                                    <a href={airbnbReservationDetails} target="_blank" rel="noreferrer">
                                                                                        <BoxArrowUpRight size={14} />
                                                                                    </a>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        <br />
                                                                    </>
                                                                )}
                                                                <a href={bookingDetailsLink} target="_blank" rel="noreferrer">Registration Link</a> <br />
                                                            </>
                                                        )}

                                                        {!needsInfo && (
                                                            <>
                                                                <Card.Title>
                                                                    {bookingGuestEntry?.main?.firstName} {bookingGuestEntry?.main?.lastName} {bookingGuestEntry?.main?.idImage && (
                                                                        <a href={bookingGuestEntry?.main?.idImage} style={{
                                                                            fontSize: '0.7rem'
                                                                        }} target="_blank" rel="noreferrer">View ID</a>
                                                                    )}
                                                                </Card.Title>
                                                                <Card.Subtitle className="mb-2">Arrival: {timeService.getFormattedTime(bookingGuestEntry?.arrivalTime)}</Card.Subtitle>
                                                                <b>Property:</b> {propertyName} <br />
                                                                <b>Status:</b> {bookingGuestEntry?.status} <br />
                                                                {bookingGuestEntry?.main?.email && (<><b>Email:</b> {bookingGuestEntry?.main?.email} <br /></>)}
                                                                {bookingGuestEntry?.main?.phone && (<><b>Phone:</b> {bookingGuestEntry?.main?.phone} <br /></>)}

                                                                {canAccess('booking', 'airbnbReservationDetails') && (
                                                                    <>
                                                                        <b>Source:</b> {bookingEntry?.source}
                                                                        {bookingEntry?.source && typeof bookingEntry?.source === 'string' && bookingSourceIcons[bookingEntry?.source] && (
                                                                            <>
                                                                                <img src={bookingSourceIcons[bookingEntry?.source]} alt={bookingEntry?.source} className="booking-source-icon" />
                                                                                {canAccess('booking', 'airbnbReservationDetails') && airbnbReservationDetails && (
                                                                                    <a href={airbnbReservationDetails} target="_blank" rel="noreferrer">
                                                                                        <BoxArrowUpRight size={14} />
                                                                                    </a>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        <br />
                                                                    </>
                                                                )}


                                                                {bookingGuestEntry?.guests && bookingGuestEntry?.guests.length > 0 && (
                                                                    <>
                                                                        <hr />
                                                                        <b>Guests: </b>
                                                                        {bookingGuestEntry?.guests.map((guest: any, gIdx: number) => {
                                                                            return (
                                                                                <a
                                                                                    href={guest.idImage}
                                                                                    key={`guest-${gIdx}`}
                                                                                    target="_blank"
                                                                                    className="badge bg-dark me-1 mb-1 text-decoration-none">
                                                                                    {guest.firstName} {guest.lastName}
                                                                                </a>
                                                                            )
                                                                        })}
                                                                        <br />
                                                                    </>
                                                                )}
                                                            </>
                                                        )}

                                                    </Card.Body>

                                                    <Card.Footer>
                                                        <>
                                                            <AccessLinkButton
                                                                action={'booking'}
                                                                color={'secondary'}
                                                                size={'sm'}
                                                                text={'View Booking'}
                                                                to={`/booking/${bookingEntry._id}`}
                                                            />
                                                            {!needsInfo && (
                                                                <AccessLinkButton
                                                                    action={'booking-guest'}
                                                                    text={'View Guest'}
                                                                    size={'sm'}
                                                                    color="secondary"
                                                                    to={`/booking-guest/${bookingGuestEntry._id}`}
                                                                />
                                                            )}
                                                            {extraButtons && extraButtons.map((button, bIdx) => (
                                                                <Button
                                                                    key={`extra-button-${bIdx}`}
                                                                    className="me-1 mb-1"
                                                                    size={'sm'}
                                                                    variant={button.variant ? button.variant : 'secondary'}
                                                                    disabled={button.disabled}
                                                                    onClick={() => button.onClick()}>
                                                                    {button.label}
                                                                </Button>
                                                            ))}

                                                        </>

                                                    </Card.Footer>

                                                </Card>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </Col>
            </Row>

            <ConfirmModal
                active={activeConfirmationModal.active}
                title={activeConfirmationModal.title}
                message={activeConfirmationModal.message}
                onConfirm={() => {
                    activeConfirmationModal.onConfirm()
                    resetConfirmationModal()
                }}
                onClose={() => {
                    activeConfirmationModal.onClose()
                    // resetConfirmationModal()
                }}
            />

        </Container>
    )
}

const stickyStyle: any = {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 10,
    paddingTop: '10px',
    paddingBottom: '10px',
}

const bookingGuestSearchFields = (propertyOptions: { value: string | number, text?: string }[]): FieldGeneratorFieldType[] => ([
    {
        fieldName: 'propertyId',
        fieldLabel: 'Property',
        fieldType: 'select',
        placeholder: '- All Properties -',
        options: propertyOptions,
    },
    {
        fieldName: 'dateRange',
        fieldLabel: 'Date Range',
        fieldType: 'dateRange',
        placeholder: 'mm/dd/yyyy - mm/dd/yyyy',
        extra: {
            startDate: 'checkIn',
            endDate: 'checkOut',
        },
    },
    {
        fieldName: 'checkIn',
        fieldLabel: 'Check In',
        fieldType: 'date',
        placeholder: 'Check In',
        required: true,
        hidden: true,
    },
    {
        fieldName: 'checkOut',
        fieldLabel: 'Check In',
        fieldType: 'date',
        placeholder: 'Check In',
        required: true,
        hidden: true,
    },
])