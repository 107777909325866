interface AccessServiceOptions {
    ignoreAdmin?: boolean
    onlyAdmin?: boolean
}

const accessInfo = {
    role: 'guest'
}

export const setRole = (role: string = 'guest') => {
    accessInfo.role = role
}

export default function canAccess(resource: string, action: string, options: AccessServiceOptions = {}) {
    const role = accessInfo.role
    if (role === 'admin' && !options.ignoreAdmin) {
        return true
    }

    if (options.onlyAdmin) {
        return false
    }

    let accessible: boolean = false

    if (ROLE_VALUES[role] && ROLE_VALUES[role][resource] && ROLE_VALUES[role][resource][action]) {
        return ROLE_VALUES[role][resource][action]
    }

    return accessible
}



// VARIABLES

const STANDARD_HOME_ACTIONS = {
    'link.cleaning': true,
    'link.tasks': true,
}

const STANDARD_BOOKING_GUEST_ACTIONS = {
    'mode.guest': true,
    'all': true,
}

const STANDARD_ROUTES = {
    // user stuff
    'account': true,
    'login': true,
    'register': true,
    'forgot-password': true,

    'cleaning': true,
    'property-task-list': true,
    'inventory-check': true,
    'confirm-booking-guests': true,
}

const ROLE_VALUES: any = {
    'association-member': {
        'routes': {
            ...STANDARD_ROUTES,
            'property': true,
            'property-bookings': true,

            'bookings': true,
            'booking': true,
            'add-booking': true,
            'edit-booking': true,
            'add-booking-quick': true,

            'booking-guest': true,
            'booking-guests': true,
            'add-booking-guest': true,
            'edit-booking-guest': true,
            'delete-booking-guest': true,
        },
        'menu': {
            'bookings': true,
            'booking-guests': true,
        },
        'home': {
            ...STANDARD_HOME_ACTIONS,
            'link.guests': true,
            'link.bookings': true,
            'propertyList': true,
        },
        'bookingGuest': {
            ...STANDARD_BOOKING_GUEST_ACTIONS,
            'mode.booking': true,

            'needsInfo': true,
            'pendingSend': true,
            'pendingConfirm': true,

            'sendConfirm': true,
            'markPending': true,
        },
    },
    association: {
        'routes': {
            ...STANDARD_ROUTES,
            'property': true,
            'properties': true,
            'property-bookings': true,
            'my-properties': true,
            'add-property': true,
            'edit-property': true,

            'bookings': true,
            'booking': true,
            'add-booking': true,
            'edit-booking': true,
            'add-booking-quick': true,

            'booking-guest': true,
            'booking-guests': true,
            'add-booking-guest': true,
            'edit-booking-guest': true,
            'delete-booking-guest': true,

            'users': true,
            'user': true,
            'add-user': true,
            'edit-user': true,

            'ical-data': true,
        },
        'menu': {
            'bookings': true,
            'booking-guests': true,
            'properties': true,
            "users": true,
        },
        'home': {
            ...STANDARD_HOME_ACTIONS,
            'link.guests': true,
            'link.bookings': true,
            'propertyList': true,
        },
        'bookingGuest': {
            ...STANDARD_BOOKING_GUEST_ACTIONS,
            // 'mode.booking': true,

            // 'needsInfo': true,
            // 'pendingSend': true,
            'pendingConfirm': true,

            'markConfirm': true,
            'markDenied': true,
        },
        "user": {
            "authorizeProperty": true
        }
    },
    cleaning: {
        'routes': {
            ...STANDARD_ROUTES,
        },
        'home': {
            ...STANDARD_HOME_ACTIONS,
            'link.guests': true,
        }
    },
    casual: {
        'routes': {
            ...STANDARD_ROUTES,
        },
        'home': {
            ...STANDARD_HOME_ACTIONS,
            'link.guests': true,
        },
        "bookingGuest": {
            'mode.booking': true,
            'mode.guest': true,

            'all': true,
            'needsInfo': true,
            'pendingSend': true,
            'pendingConfirm': true,
            'confirmed': true,

            'markConfirm': true,
            'markPending': true,
            'markDenied': true,
            'sendConfirm': true,
        }
    },
    'front-desk': {
        'routes': {
            ...STANDARD_ROUTES,
            'property': true,
            // 'properties': true,
            'property-bookings': true,
            'my-properties': true,
            // 'add-property': true,
            // 'edit-property': true,
            "financials-property": true,


            "handle-property-prices": true,

            'bookings': true,
            'booking': true,
            // 'add-booking': true,
            'edit-booking': true,
            // 'add-booking-quick': true,

            "unconfirmed-bookings": true,
            "upcoming-bookings": true,

            "adr-occupancy-reporter": true,

            "fontaine-tools": true,

            'date-blocks': true,
            'date-block': true,
            'add-date-block': true,
            'edit-date-block': true,

            'booking-guest': true,
            'booking-guests': true,
            // 'add-booking-guest': true,
            // 'edit-booking-guest': true,
            // 'delete-booking-guest': true,

            'ical-data': true,

            "bukins": true,
            "bukin": true,
            "add-bukin": true,
            "edit-bukin": true,
            // "delete-bukin": true,

            "bukibles": true,
            "bukible": true,
            // "add-bukible": true,
            // "edit-bukible": true,
            // "delete-bukible": true,
        },
        'menu': {
            'bookings': true,
            'date-blocks': true,
            'booking-guests': true,
            'properties': true,
            "bukibles": true,
            "bukins": true,
        },
        'home': {
            ...STANDARD_HOME_ACTIONS,
            'mainLinks': true,
            'link.guests': true,
            // 'link.bookings': true,
            'link.bukibles': true,
            'propertyList': true,
            'link.reports': true,
        },
        "booking": {
            'form.requiresConfirmation': true,
        },
        'bookingGuest': {
            'mode.guest': true,
            'mode.booking': true,

            'confirmed': true,
            'needsInfo': true,
            'pendingSend': true,
            'pendingConfirm': true,

            'markConfirm': true,
            'markDenied': true,
            'sendConfirm': true,
        },
        "user": {
            // "authorizeProperty": true
        }
    },
    maintenance: {
        'routes': {
            ...STANDARD_ROUTES,
        },
        'home': {
            ...STANDARD_HOME_ACTIONS,
        }
    },
    'maintenance-lead': {
        'routes': {
            ...STANDARD_ROUTES,
        },
        'home': {
            ...STANDARD_HOME_ACTIONS,
        }
    },
    manager: {
        'routes': {
            ...STANDARD_ROUTES,
            'property': true,
            'properties': true,
            'property-bookings': true,
            'my-properties': true,
            'add-property': true,
            'edit-property': true,

            'bookings': true,
            'booking': true,
            'add-booking': true,
            'edit-booking': true,
            'add-booking-quick': true,

            "unconfirmed-bookings": true,
            "upcoming-bookings": true,

            'date-blocks': true,
            'date-block': true,
            'add-date-block': true,
            'edit-date-block': true,

            'booking-guest': true,
            'booking-guests': true,
            'add-booking-guest': true,
            'edit-booking-guest': true,
            'delete-booking-guest': true,

            'ical-data': true,

            "bukins": true,
            "bukin": true,
            "add-bukin": true,
            "edit-bukin": true,
            "delete-bukin": true,

            "bukibles": true,
            "bukible": true,
            "add-bukible": true,
            "edit-bukible": true,
            "delete-bukible": true,
        },
        'menu': {
            'bookings': true,
            'date-blocks': true,
            'booking-guests': true,
            'properties': true,
            "bukibles": true,
            "bukins": true,
        },
        'home': {
            ...STANDARD_HOME_ACTIONS,
            'mainLinks': true,
            'link.guests': true,
            'link.bookings': true,
            'link.bukibles': true,
            'propertyList': true,
        },
        "booking": {
            'form.requiresConfirmation': true,
        },
        'bookingGuest': {
            'mode.guest': true,
            'mode.booking': true,

            'confirmed': true,
            'needsInfo': true,
            'pendingSend': true,
            'pendingConfirm': true,

            'markConfirm': true,
            'markDenied': true,
            'sendConfirm': true,
        },
        "user": {
            // "authorizeProperty": true
        }
    },
    owner: {
        'routes': {

            // user stuff
            'account': true,
            'login': true,
            'register': true,
            'forgot-password': true,

            'property': true,
            'properties': true,
            'property-bookings': true,

            "adr-occupancy-reporter": true,
            "financials-property": true,

            'bookings': true,
            'booking': true,
            "upcoming-bookings": true,
        },
        'menu': {
            'bookings': true,
            // 'properties': true,
        },
        'home': {
            'link.bookings': true,
            'propertyList': true,
            'link.reports': true,
            'link.availableUpcoming': true,
        },
    },
    viewer: {
        'routes': {
            ...STANDARD_ROUTES,
            'property': true,
            // 'properties': true,
            'property-bookings': true,
            'my-properties': true,
            // 'add-property': true,
            // 'edit-property': true,
            "financials-property": true,

            // tasks
            "property-task-reporter": true,


            "handle-property-prices": true,

            'bookings': true,
            'booking': true,
            // 'add-booking': true,
            // 'edit-booking': true,
            // 'add-booking-quick': true,

            "unconfirmed-bookings": true,
            "upcoming-bookings": true,

            "adr-occupancy-reporter": true,

            // 'date-blocks': true,
            // 'date-block': true,
            // 'add-date-block': true,
            // 'edit-date-block': true,

            'booking-guest': true,
            'booking-guests': true,
            // 'add-booking-guest': true,
            // 'edit-booking-guest': true,
            // 'delete-booking-guest': true,

            'ical-data': true,

            "bukins": true,
            "bukin": true,
            // "add-bukin": true,
            // "edit-bukin": true,
            // "delete-bukin": true,

            "bukibles": true,
            "bukible": true,
            // "add-bukible": true,
            // "edit-bukible": true,
            // "delete-bukible": true,
        },
        'menu': {
            'bookings': true,
            'date-blocks': true,
            'booking-guests': true,
            'properties': true,
            "bukibles": true,
            "bukins": true,
            "property-tasks": true,
        },
        'home': {
            ...STANDARD_HOME_ACTIONS,
            'mainLinks': true,
            'link.guests': true,
            // 'link.bookings': true,
            'link.bukibles': true,
            'propertyList': true,
            'link.reports': true,
        },
        "booking": {
            // 'form.requiresConfirmation': true,
        },
        'bookingGuest': {
            'mode.guest': true,
            'mode.booking': true,

            'confirmed': true,
            'needsInfo': true,
            'pendingSend': true,
            'pendingConfirm': true,

            // 'markConfirm': true,
            // 'markDenied': true,
            // 'sendConfirm': true,
        },
        "user": {
            // "authorizeProperty": true
        }
    },
}

