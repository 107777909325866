import { useMemo, useState } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import CleaningEntry from "../../Components/Cleaning/CleaningEntry";
import { FieldGeneratorFieldType } from "../../Components/FormFields/FieldGenerator/FieldGenerator";
import QuickForm from "../../Components/Forms/QuickForm/QuickForm";
import { textBooleanHandler } from "../../Helpers/ValueHandlers/boolHandler";
import useMyProperties from "../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useBookingsByPropertyMemo from "../../Memos/Bookings/UseBookingsByPropertyMemo";
import timeService from "../../services/time/timeService";
import canAccess from "../../services/accessService/accessService";
import BookingModal, { useBookingModal } from "../../Components/Bookings/BookingModal/BookingModal";
import { useGetBookingsQuery } from "../../services/bClientApi";

const DATE_FORMAT = 'yyyy/LL/dd'

export default function CleaningPage() {

    const canEditBooking = canAccess('routes', 'edit-booking')

    const { myProperties } = useMyProperties()

    const startOfToday = timeService.getCurrentTime().startOf('day').toMillis()
    const queryEndDate = timeService.getCurrentTime().plus({ weeks: 1 }).startOf('day').toMillis()
    const [
        activeQuery,
        setActiveQuery
    ] = useState<any>({
        query: {
            checkIn: startOfToday,
            checkOut: queryEndDate,
            bookingStatus: ['new', 'pending', 'confirmed'],
        },
        limit: 500,
        page: 1
    })


    const {
        data,
        isLoading,
        error,
    } = useGetBookingsQuery({
        bookingsQuery: {
            ...activeQuery.query,
            propertyId: Object.keys(myProperties || {}),
        },
        pagination: {
            page: activeQuery.page,
            limit: activeQuery.limit,
            all: true,
            sort: {}
        }
    }, {
        skip: Object.keys(myProperties).length === 0,
    })

    const bookingsList = data?.docs || []

    const {
        editBookingModal,
        clearBookingModal,
        onEditClick,
        onCheckOutClick,
        bookingModalonSubmit
    } = useBookingModal()

    const [generalFilters, setGeneralFilters] = useState<any>({
        showCheckOuts: true,
        showCheckIns: true,
        showEmpty: true,
        showOccupied: false,
        allProperties: true,
    })

    // const [expandAllProperties, setExpandAllProperties] = useState<boolean>(false)
    // const [openProperties, setOpenProperties] = useState<any>({})

    const bookingsByProperty = useBookingsByPropertyMemo(bookingsList, {
        propertyData: myProperties ? myProperties : {},
        bookingProcessor: (bookingList: any[]) => {
            return bookingList.sort((x: any, y: any) => timeService.dateToMillis(x.checkOut) - timeService.dateToMillis(y.checkOut)).map(
                (x: any, idx: number, a: any[]) => {
                    const b = { ...x, }
                    if (idx < a.length - 1) { b.nextCheckIn = a[idx + 1] }
                    return b
                }
            )
        }
    })

    const bookingsByDay = useMemo(() => {
        // Calendar Bookings component

        const today = timeService.getCurrentTime()
        const todayDay = today.toFormat(DATE_FORMAT)
        const todayTime = today.toMillis()

        const bookingByDayList: any[] = []

        if (isLoading || Object.keys(myProperties).length === 0) {
            return bookingByDayList
        }

        let currentDate = timeService.createLuxonDate(activeQuery.query.checkIn).minus({ days: 1 })
        let currentDay = currentDate.toFormat(DATE_FORMAT)
        // let currentDay = timeService.createLuxonDate(activeQuery.query.checkIn).minus({ days: 1 }).toFormat(DATE_FORMAT)
        const endDate = timeService.getFormattedTime(activeQuery.query.checkOut, DATE_FORMAT)

        while (currentDay !== endDate) {
            currentDate = currentDate.plus({ days: 1 })
            currentDay = currentDate.toFormat(DATE_FORMAT)
            // currentDay = timeService.createLuxonDate(currentDay).plus({ days: 1}).toFormat(DATE_FORMAT)

            const day = currentDate.toMillis()
            // const day = timeService.createLuxonDate(currentDay).toMillis()

            const bookedProperties: { [v: string]: boolean } = {}

            const propertyWithBookingsRef: any = {}

            const currentDayInfo: any = {
                day: currentDay,
                isToday: currentDay === todayDay,
                bookings: [],
                emptyProperties: [],
                emptyRightNow: [],
                emptyRightNowRef: {},
                activeReservations: [],
                checkIns: [],
                checkOuts: [],
            }

            for (const p of bookingsByProperty) {
                propertyWithBookingsRef[p.propertyId] = p
                if (myProperties) {

                    if (generalFilters.propertyFilter &&
                        myProperties[p.propertyId] &&
                        JSON.stringify(myProperties[p.propertyId]).toLowerCase().indexOf(generalFilters.propertyFilter.toLowerCase()) === -1
                    ) {
                        continue
                    }

                    if (!myProperties[p.propertyId]) {
                        continue
                    }
                    if (!generalFilters.allProperties && !myProperties[p.propertyId].services.cleaning) {
                        continue
                    }
                    if (!myProperties[p.propertyId].public) {
                        continue
                    }
                }

                for (const b of p.bookings) {
                    const checkIn = timeService.dateToMillis(b.checkIn)
                    const checkInDay = timeService.getFormattedTime(b.checkIn, DATE_FORMAT)
                    const checkOut = timeService.dateToMillis(b.checkOut)
                    const checkOutDay = timeService.getFormattedTime(b.checkOut, DATE_FORMAT)
                    //mark booked properties and active reservations
                    if (
                        (checkIn <= day && day <= checkOut) ||
                        checkInDay === currentDay ||
                        checkOutDay === currentDay
                    ) {
                        bookedProperties[p.propertyId] = true
                        currentDayInfo.bookings.push(b)
                        if (checkOutDay !== currentDay) {
                            currentDayInfo.activeReservations.push(b)
                        }
                    }
                    // handle checkins
                    if (checkInDay === currentDay) {
                        currentDayInfo.checkIns.push(b)
                    }
                    // handle checkouts
                    if (checkOutDay === currentDay) {
                        currentDayInfo.checkOuts.push(b)
                    }
                }
            }

            if (myProperties) {
                for (const pId in myProperties) {

                    if (generalFilters.propertyFilter &&
                        myProperties[pId] &&
                        JSON.stringify(myProperties[pId]).toLowerCase().indexOf(generalFilters.propertyFilter.toLowerCase()) === -1
                    ) {
                        continue
                    }

                    if (!generalFilters.allProperties && !myProperties[pId]?.services?.cleaning) {
                        continue
                    }
                    if (!myProperties[pId].public) {
                        continue
                    }

                    // Handle empty properties
                    if (!bookedProperties[pId]) {
                        const emptyPropertyEntry: any = {
                            id: pId,
                            nextCheckIn: null
                        }
                        if (propertyWithBookingsRef[pId]) {
                            const pNextCheckIn = propertyWithBookingsRef[pId].bookings.filter((b: any) => day < timeService.dateToMillis(b.checkIn))
                            if (pNextCheckIn.length > 0) {
                                emptyPropertyEntry.nextCheckIn = pNextCheckIn[0]
                            }
                        }
                        currentDayInfo.emptyProperties.push(emptyPropertyEntry)
                    }

                    // handle empty right now
                    if (currentDay === todayDay) {
                        const emptyRightNowEntry: any = {
                            id: pId,
                            propertyId: pId,
                            nextCheckIn: null,
                            nextCheckInToday: false,
                            checkOutToday: false,
                            occupied: false,
                        }

                        if (propertyWithBookingsRef[pId]) {
                            for (const b of propertyWithBookingsRef[pId].bookings) {
                                const checkIn = timeService.dateToMillis(b.checkIn)
                                const checkInDay = timeService.getFormattedTime(b.checkIn, DATE_FORMAT)
                                const checkOut = timeService.dateToMillis(b.checkOut)
                                const checkOutDay = timeService.getFormattedTime(b.checkOut, DATE_FORMAT)
                                // checks for active reservation for today
                                if (checkIn <= day && day <= checkOut) {
                                    // sees if there is a checkout today and mark is as potentially empty today
                                    if (checkOutDay === currentDay) {
                                        emptyRightNowEntry.checkOutToday = true
                                    }
                                }
                                // check right now right now
                                if (checkIn <= todayTime && todayTime <= checkOut) {
                                    emptyRightNowEntry.occupied = true
                                }
                                if (day < checkIn) {
                                    emptyRightNowEntry.nextCheckIn = b
                                    if (checkInDay === currentDay) {
                                        emptyRightNowEntry.nextCheckInToday = true
                                    }
                                    break
                                }
                            }
                        }

                        if (!emptyRightNowEntry.occupied) {
                            currentDayInfo.emptyRightNow.push(emptyRightNowEntry)
                            currentDayInfo.emptyRightNowRef[pId] = true
                        }
                    }
                }
            }

            bookingByDayList.push(currentDayInfo)
        }
        return bookingByDayList
    }, [myProperties, activeQuery.query.checkIn, activeQuery.query.checkOut, bookingsByProperty, isLoading, generalFilters.allProperties, generalFilters.propertyFilter])


    return (
        <Container>
            <Row>
                <Col>
                    <h1>Cleaning</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    <QuickForm
                        initialData={{
                            dateRange: null,
                            checkIn: new Date(activeQuery.query.checkIn),
                            checkOut: new Date(activeQuery.query.checkOut),
                            showCheckOuts: true,
                            showCheckIns: true,
                            showEmpty: true,
                            showOccupied: false,
                            allProperties: true,
                            propertyFilter: '',
                        }}
                        fields={cleaningSearchFields}
                        onChange={(data: any) => {
                            const fieldName = data.target.name
                            const fieldValue = data.target.value
                            if (quickFilterFields[fieldName]) {
                                const newFilters = {
                                    ...generalFilters,
                                    [fieldName]: quickFilterFields[fieldName].handler ? quickFilterFields[fieldName].handler(fieldValue) : fieldValue,
                                }
                                setGeneralFilters(newFilters)
                            }

                        }}
                        onSubmit={(d: any) => {
                            if (d.checkIn && d.checkOut) {
                                const newQuery = {
                                    ...activeQuery,
                                    query: {
                                        ...activeQuery.query,
                                        checkIn: timeService.createLuxonDate(d.checkIn).startOf('day').toMillis(),
                                        checkOut: timeService.createLuxonDate(d.checkOut).endOf('day').toMillis(),
                                    }
                                }
                                setActiveQuery(newQuery)
                            }
                        }}
                    />


                    {/* {bookingsList?.length} Bookings <br /> */}

                    {bookingsByDay.map((day) => (
                        <div key={day.day}>
                            <h3 style={{
                                position: 'sticky',
                                top: 0,
                                backgroundColor: 'white',
                                zIndex: 10,
                                paddingTop: '10px',
                                paddingBottom: '10px',
                            }}> <b>{day.isToday ? 'TODAY' : ''}</b> {timeService.getOffsetFormattedTime(day.day, 'LL/dd/yyyy cccc')}</h3>
                            <hr />

                            {generalFilters.showCheckOuts && day.checkOuts && day.checkOuts.length > 0 && (
                                <div className="cleaning-wrapper">
                                    <h4>
                                        Checkouts <Badge bg="info">{day.checkOuts.length}</Badge>
                                    </h4>
                                    <hr />
                                    {day.checkOuts.map((b: any, idx: number) => {

                                        const propertyInfo = myProperties[b.propertyId]

                                        return (
                                            <CleaningEntry
                                                key={`${b._id}-checkout-${idx}`}
                                                bookingInfo={b}
                                                propertyInfo={propertyInfo}
                                                nextCheckIn={b.nextCheckIn}
                                                colorBg={day.isToday}
                                                onEdit={canEditBooking ? onEditClick : undefined}
                                                onCheckedOut={canEditBooking ? onCheckOutClick : undefined}
                                                emptyRef={day.emptyRightNowRef[b.propertyId]}
                                            />
                                        )
                                    })}
                                </div>
                            )}

                            {generalFilters.showCheckIns && day.checkIns && day.checkIns.length > 0 && (
                                <div className="cleaning-wrapper">
                                    <h4>
                                        Checkins <Badge bg="info">{day.checkIns.length}</Badge>
                                    </h4>
                                    <hr />
                                    {day.checkIns.map((b: any, idx: number) => {

                                        const propertyInfo = myProperties[b.propertyId]

                                        return (
                                            <CleaningEntry
                                                key={`${b._id}-checkin-${idx}`}
                                                bookingInfo={b}
                                                propertyInfo={propertyInfo}
                                                nextCheckIn={b.nextCheckIn}
                                                colorBg={day.isToday}
                                                onEdit={canEditBooking ? onEditClick : undefined}
                                                emptyRef={day.emptyRightNowRef[b.propertyId]}
                                            />
                                        )
                                    })}
                                </div>
                            )}

                            {generalFilters.showEmpty && day.isToday && day.emptyRightNow && day.emptyRightNow.length > 0 && (
                                <div className="cleaning-wrapper">
                                    <h4>
                                        Empty Right Now <Badge bg="info">{day.emptyRightNow.length}</Badge>
                                    </h4>
                                    <hr />
                                    {day.emptyRightNow.map((b: any, idx: number) => {

                                        const propertyInfo = myProperties[b.propertyId]

                                        return (
                                            <CleaningEntry
                                                key={`${b.id}-emptyRightNow-${idx}`}
                                                propertyInfo={propertyInfo}
                                                nextCheckIn={b.nextCheckIn}
                                                nextCheckInToday={b.nextCheckInToday}
                                                checkedOutToday={b.checkOutToday}
                                                dayRef={day.day}
                                            />
                                        )
                                    })}
                                </div>
                            )}

                            {generalFilters.showEmpty && !day.isToday && day.emptyProperties && day.emptyProperties.length > 0 && (
                                <div className="cleaning-wrapper">
                                    <h4>
                                        Empty Properties <Badge bg="info">{day.emptyProperties.length}</Badge>
                                    </h4>
                                    <hr />
                                    {day.emptyProperties.map((b: any, idx: number) => {

                                        const propertyInfo = myProperties[b.id]

                                        return (
                                            <CleaningEntry
                                                key={`${b.id}-emptyProperties-${idx}`}
                                                propertyInfo={propertyInfo}
                                                nextCheckIn={b.nextCheckIn}
                                                dayRef={day.day}
                                            />
                                        )
                                    })}
                                </div>
                            )}

                            {generalFilters.showOccupied && day.activeReservations && day.activeReservations.length > 0 && (
                                <div className="cleaning-wrapper">
                                    <h4>
                                        Occupied <Badge bg="info">{day.activeReservations.length}</Badge>
                                    </h4>
                                    <hr />
                                    {day.activeReservations.map((b: any, idx: number) => {

                                        const propertyInfo = myProperties[b.propertyId]

                                        return (
                                            <CleaningEntry
                                                key={`${b._id}-activeReservations-${idx}`}
                                                bookingInfo={b}
                                                propertyInfo={propertyInfo}
                                                nextCheckIn={b.nextCheckIn}
                                                colorBg={day.isToday}
                                                onEdit={canEditBooking ? onEditClick : undefined}
                                                emptyRef={day.emptyRightNowRef[b.propertyId]}
                                            />
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    ))}

                </Col>
            </Row>

            <BookingModal
                active={editBookingModal.active}
                booking={editBookingModal.booking}
                clearBookingModal={clearBookingModal}
                bookingModalonSubmit={bookingModalonSubmit}
            />


        </Container>
    )
}
const boolHandler = {
    handler: textBooleanHandler
}

const quickFilterFields: any = {
    showCheckIns: boolHandler,
    showCheckOuts: boolHandler,
    showOccupied: boolHandler,
    showEmpty: boolHandler,
    allProperties: boolHandler,
    propertyFilter: {
        handler: (val: any) => val
    }
}

const cleaningSearchFields: FieldGeneratorFieldType[] = [
    {
        fieldName: 'dateRange',
        fieldLabel: 'Date Range',
        fieldType: 'dateRange',
        placeholder: 'mm/dd/yyyy - mm/dd/yyyy',
        extra: {
            startDate: 'checkIn',
            endDate: 'checkOut',
        }
    },
    {
        fieldName: 'propertyFilter',
        fieldLabel: 'Property Filter',
        fieldType: 'text',
        required: false,
        // layout: {
        //     xs: 12,
        //     md: 6,
        // }
    },
    {
        fieldName: 'checkIn',
        fieldLabel: 'Check In',
        fieldType: 'date',
        required: true,
        hidden: true,

    },
    {
        fieldName: 'checkOut',
        fieldLabel: 'Check Out',
        fieldType: 'date',
        required: true,
        hidden: true,
    },
    {
        fieldName: 'showCheckIns',
        fieldLabel: 'Show Check Ins',
        fieldType: 'checkbox',
        required: false,
        layout: {
            xs: 6,
            md: 3,
        }
    },
    {
        fieldName: 'showCheckOuts',
        fieldLabel: 'Show Check Outs',
        fieldType: 'checkbox',
        required: false,
        layout: {
            xs: 6,
            md: 3,
        }
    },
    {
        fieldName: 'showOccupied',
        fieldLabel: 'Show Occupied',
        fieldType: 'checkbox',
        required: false,
        layout: {
            xs: 6,
            md: 3,
        }
    },
    {
        fieldName: 'showEmpty',
        fieldLabel: 'Show Empty',
        fieldType: 'checkbox',
        required: false,
        layout: {
            xs: 6,
            md: 3,
        }
    },
    {
        fieldName: 'allProperties',
        fieldLabel: 'All Properties',
        fieldType: 'checkbox',
        required: false,
        layout: {
            xs: 6,
            md: 3,
        }
    },

]