import { useState } from "react"
import { API_URL } from "../../Config/config"
import { useAppDispatch } from "../../redux/store"
import { showError, showSuccess } from "../../redux/counter/counterSlice"
// import { useUploadImageMutation } from "../../services/bClientApi"

export default function useUploadImage() {

    const dispatch = useAppDispatch()

    // const [
    //     uploadImage,
    //     {
    //         error,
    //         isLoading
    //     }
    // ] = useUploadImageMutation() 

    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [imageUploadProgress, setImageUploadProgress] = useState('')
    
    const uploadImageFn = async (imageInfo: { imageToUpload: any, filename: string }) => {

        let uploadedImageUrl = ''
        setIsLoading(true)
        setImageUploadProgress(`Processing...`)
        setError('')

        try {
            // const uploadedImageUrl = await uploadImage(imageInfo).unwrap()
            // return uploadedImageUrl.imageUrl
            uploadedImageUrl = await uploadImage(imageInfo)
            dispatch(showSuccess(`Image uploaded successfully`))
        } catch (error) {
            console.log(`Error uploading image: ${error}`)
            setError(`Error uploading image: ${error}`)
            dispatch(showError(`Error uploading image: ${error}`))
        }

        setIsLoading(false)
        setImageUploadProgress(``)
        return uploadedImageUrl
    }

    const uploadImageMultiFn = async (imageInfo: { imageToUpload: any, filename: string }[]) => {
        const uploadedImageUrls = []
        setIsLoading(true)
        setImageUploadProgress(`Processing...`)
        setError('')

        try {
            for (let i = 0; i < imageInfo.length; i++) {
                // const uploadedImageUrl = await uploadImage(imageInfo[i]).unwrap()
                // uploadedImageUrls.push(uploadedImageUrl.imageUrl)
                setImageUploadProgress(`Processing ${i + 1} of ${imageInfo.length}`)
                const uploadedImageUrl = await uploadImage(imageInfo[i])
                uploadedImageUrls.push(uploadedImageUrl)
            }
            dispatch(showSuccess(`Images uploaded successfully`))
        } catch (error) {
            console.log(`Error uploading images: ${error}`)
            setError(`Error uploading images: ${error}`)
            dispatch(showError(`Error uploading images: ${error}`))
        }

        setIsLoading(false)
        setImageUploadProgress(``)
        return uploadedImageUrls
    }


    return { 
        uploadImageFn, 
        uploadImageMultiFn, 
        imageUploadProgress,
        error,
        isLoading
    }
}


export async function uploadImage(uploadImageInfo: { imageToUpload: any, filename: string }) {
    const { imageToUpload, filename } = uploadImageInfo
    imageToUpload.lastModifiedDate = new Date()
    imageToUpload.name = filename
    const formData = new FormData()
    formData.append('image', imageToUpload, filename)
    const uploadedImageData = await fetch(`${API_URL}/upload/image-upload`, {
        method: 'POST',
        body: formData,
        cache: 'no-store'
    }).then((res) => {
        return res.json()
    }).catch(err => {
        return {
            error: err
        }
    })
    if (uploadedImageData && uploadedImageData.imageUrl) {
        return uploadedImageData.imageUrl
    }
    if(uploadedImageData && uploadedImageData.error) {
        throw new Error(uploadedImageData.error)
    }
    return ''
}