import { Toast, ToastContainer } from "react-bootstrap"
import timeService from "../../../services/time/timeService"
import { Link } from "react-router-dom"
import { currencyFormatter } from "../../../Helpers/Parsers/currencyFormatter"
import { bookingSourceIcons } from "../../../Components/Bookings/BookingEntry/BookingEntry"
import { airBnbReservationDetailsLink } from "../../../Helpers/Booking/airbnbReservationDetails"
import { BoxArrowUpRight } from "react-bootstrap-icons"
import canAccess from "../../../services/accessService/accessService"

export default function BookingPreview(props: {
    booking: any,
    onClose: () => void
}) {
    const {
        booking,
        onClose = () => { }
    } = props

    if (!booking) {
        return null
    }

    const nights = timeService.calculateNights(booking.checkIn, booking.checkOut)
    const earnings = currencyFormatter(booking.pricePerNight * nights)

    const airbnbReservationDetails = airBnbReservationDetailsLink(booking)

    return (
        <div className="toast-fix">
            <ToastContainer>
                <Toast show={booking && booking._id ? true : false} onClose={() => {
                    onClose()
                }}>
                    <Toast.Header>
                        <strong className="me-auto">
                            {booking.contactInfo?.firstName} {booking.contactInfo?.lastName}
                        </strong>
                        <small>{booking._id}</small>
                    </Toast.Header>
                    <Toast.Body>
                        {booking.checkIn && booking.checkOut && (
                            <>
                                <b>Check In:</b> {timeService.createLuxonDate(booking.checkIn).toFormat('MM/dd/yyyy')}<br />
                                <b>Check Out:</b> {timeService.createLuxonDate(booking.checkOut).toFormat('MM/dd/yyyy')}<br />
                            </>
                        )}
                        {booking.dateBooked && (
                            <>
                                <b>Date Booked:</b> {timeService.createLuxonDate(booking.dateBooked).toFormat('MM/dd/yyyy')}<br />
                            </>
                        )}
                        <b>Nights: </b> {nights}<br />
                        <b>Earnings: </b> {earnings}<br />
                        <b>Source:</b> {booking.source}
                        {booking.source && typeof booking.source === 'string' && bookingSourceIcons[booking.source] && (
                            <>
                                <img src={bookingSourceIcons[booking.source]} alt={booking.source} className="booking-source-icon" />
                                {canAccess('booking', 'airbnbReservationDetails') && airbnbReservationDetails && (
                                    <a href={airbnbReservationDetails} target="_blank" rel="noreferrer">
                                        <BoxArrowUpRight size={14} />
                                    </a>
                                )}
                            </>
                        )} <br />
                        <b>Confirmation:</b> {booking.sourceConfirmation}<br />

                        <Link to={`/booking/${booking._id}`}>
                            View Booking
                        </Link>

                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    )
}