import { Badge, Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import timeService from "../../services/time/timeService"
import { currencyFormatter } from "../../Helpers/Parsers/currencyFormatter"
import { FRONTEND_URL } from "../../Config/config"

export default function PurchaseEntry(props: {
    purchaseData: any,
    fullView?: boolean,
}) {
    const {
        purchaseData = {},
        fullView = false,
    } = props

    const {
        firstName,
        lastName,
        email,
        phoneNumber,
        referenceNumber,
        _id,
        transactionId,
        paymentIntent,
        paymentStatus,
        sessionStatus,
        merchant,
        dateCreated,
        emailSent,
        products,
        message
    } = purchaseData

    let total = 0
    if (products && products.length > 0) {
        total = products.reduce((acc: number, p: any) => {
            return acc + p.productPrice
        }, 0)
    }

    const isTestTransaction = transactionId && transactionId.includes('test_')
    const stripeUrl = isTestTransaction ? `https://dashboard.stripe.com/test/payments/${paymentIntent}` : `https://dashboard.stripe.com/payments/${paymentIntent}`
    const thankYouLink = `${FRONTEND_URL}/purchase/success?transactionID=${transactionId}&cartID=${_id}`
    return (
        <Card
            style={{ backgroundColor: `#f9f9f9` }}
            className="mb-3">
            <Card.Body>
                <Card.Title>
                    {firstName} {lastName}
                </Card.Title>

                {/* {JSON.stringify(purchaseData, null, 2)} <br /> */}
                <Card.Text className="mb-3 small">
                    {timeService.getFormattedTime(dateCreated)}
                </Card.Text>

                {fullView && (
                    <>
                        <hr />
                        <Card.Subtitle className="mb-2">
                            <b>
                                Contact Info:
                            </b>
                        </Card.Subtitle>
                        <Card.Text className="mb-1 small">
                            <b>Email:</b> {email}
                        </Card.Text>
                        <Card.Text className="mb-1 small">
                            <b>Phone:</b> {phoneNumber}
                        </Card.Text>
                        {message && (
                            <Card.Text className="mb-1 small">
                                <b>Message:</b> {message}
                            </Card.Text>
                        )}
                        <hr />
                        <Card.Subtitle className="mb-2">
                            <b>
                                Transaction Info:
                            </b>
                        </Card.Subtitle>
                        <Card.Text className="mb-1 small">
                            <b>Reference Number:</b> {referenceNumber}&nbsp;
                            <Link to={`/bookings?query={"sourceConfirmation":"${referenceNumber}"}`}>View</Link>
                        </Card.Text>
                        <Card.Text className="mb-1 small">
                            <b>Transaction Id:</b> {transactionId}
                        </Card.Text>
                        <Card.Text className="mb-1 small">
                            <b>Payment Intent:</b> {paymentIntent}
                            {paymentIntent && (
                                <>
                                    &nbsp;<a href={stripeUrl} target="_blank" rel="noreferrer">View in Stripe</a>
                                </>
                            )}
                        </Card.Text>

                        {/* <Card.Subtitle className="mb-2">
                            Merchant Info:
                        </Card.Subtitle> */}
                        <Card.Text className="mb-1 small">
                            <b>Merchant:</b> {merchant}
                        </Card.Text>

                        {/* <Card.Subtitle className="mb-2">
                            Email Info:
                        </Card.Subtitle> */}
                        <Card.Text className="mb-1 small">
                            <b>Email Sent:</b> {emailSent ? 'yes' : 'no'}
                        </Card.Text>

                        {paymentStatus === 'paid' && (
                            <Card.Text className="mb-1 small">
                                <a href={thankYouLink} target="_blank" rel="noreferrer">Thank You Page</a>
                            </Card.Text>
                        )}

                        <hr />
                    </>
                )}

                <Card.Subtitle className="mb-2">
                    <b>
                        Products:
                    </b>
                </Card.Subtitle>
                {products && products.map((p: any, idx: number) => {
                    let bookingLink = ''
                    if (p.productType === 'booking' || p.productType === 'child-booking') {
                        bookingLink = `/booking/${p.productId}`
                    }
                    let parkingLink = ''
                    if (p.productType === 'parking') {
                        // const [_parkingString, _bukibleId, bukinId] = p.productId.split('-')
                        const splitProductId = p.productId.split('-')
                        const bukinId = splitProductId[2]
                        parkingLink = `/bukin/${bukinId}`
                    }
                    return (
                        <Card.Text key={idx} className={`small mb-1`}>
                            {p.productName} - <span className="text-success">{currencyFormatter(p.productPrice)}</span>
                            {bookingLink && (
                                <>
                                    &nbsp; <Link to={bookingLink}>View Booking</Link>
                                </>
                            )}
                            {parkingLink && (
                                <>
                                    &nbsp; <Link to={parkingLink}>View Parking</Link>
                                </>
                            )}
                        </Card.Text>
                    )
                })}

                <Card.Text className="mb-2">
                    <b>Total: </b> <span className="text-success">{currencyFormatter(total)}</span>
                </Card.Text>
                {!fullView && (
                    <>
                        <hr />
                        <Card.Text className="mb-1 small">
                            <b>Reference Number:</b> {referenceNumber}&nbsp;
                            <Link to={`/bookings?query={"sourceConfirmation":"${referenceNumber}"}`}>View</Link>
                        </Card.Text>
                    </>
                )}


            </Card.Body>
            <Card.Footer>
                <div className="purchase-info-badges">
                    {/* payment status badge */}

                    <Badge
                        bg={paymentStatusColors[paymentStatus] ? paymentStatusColors[paymentStatus].bg : 'secondary'}
                        text={paymentStatusColors[paymentStatus] ? paymentStatusColors[paymentStatus].text : 'light'}
                        className="me-2 mb-2">
                        {paymentStatus}
                    </Badge>

                    <Badge
                        bg={sessionStatus === 'complete' ? 'success' : 'warning'}
                        text={sessionStatus === 'complete' ? 'light' : 'dark'}
                        className="me-2 mb-2">
                        {sessionStatus}
                    </Badge>


                </div>

                <Link className="btn btn-primary btn-sm me-2 mb-2" to={`/purchase/${_id}`}>View</Link>
                <Link className="btn btn-secondary btn-sm me-2 mb-2" to={`/edit-purchase/${_id}`}>Edit</Link>
                {/* <Link className="btn btn-danger btn-sm me-2 mb-2" to={`/delete-purchase/${_id}`}>Delete</Link> */}
            </Card.Footer>
        </Card>
    )


}

const paymentStatusColors: any = {
    'paid': { bg: 'success', text: 'light', },
    'unpaid': { bg: 'warning', text: 'dark', },
    'refunded': { bg: 'danger', text: 'light', },
}