import { PropertyBookingEntry } from "../../Types/bookingTypes";

export const airBnbReservationDetailsLink = (bookingData: PropertyBookingEntry) => {
    let airbnbReservationDetails = ''

    if (bookingData && bookingData.source === 'airbnb' && bookingData.sourceConfirmation) {
        // https://www.airbnb.com/hosting/reservations/details/HM2HCEP4PP
        airbnbReservationDetails = `https://www.airbnb.com/hosting/reservations/details/${bookingData.sourceConfirmation}`
    }
    return airbnbReservationDetails
}