import { useState } from "react"
import { Badge, Col, Container, Row } from "react-bootstrap";
// import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import BookingForm, { PropertyBookingFormEntry } from "../../../Components/Forms/BookingForm/BookingForm";
import { isParent } from "../../../Helpers/Property/isParent";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import useRelatedBookings from "../../../Hooks/UseRelatedBookings/UseRelatedBookings";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { PropertyBookingEntry } from "../../../Types/bookingTypes";
import { PropertyLinkedPropertyType } from "../../../Types/propertyTypes";
import BookingPreview from "../Booking/BookingPreview";
import { useBulkEditBookingsMutation, useEditBookingMutation, useGetBookingByIdQuery, useLazyGetBookingsQuery } from "../../../services/bClientApi";

export default function EditBookingPage() {

  const { 
    bookingId = ''
  } = useParams()

  const {
    data: bookingData,
    isLoading,
    error,
  } = useGetBookingByIdQuery(bookingId)

  const { _id } = bookingData || {}

  const { myProperties } = useMyProperties()
  const propertyOptions = useMyPropertiesSelectValues(myProperties)
  const dispatch = useAppDispatch()
  const [dateValidationStatus, setDateValidationStatus] = useState({
    available: true,
    status: '',
    message: ''
  })

  const [
    editBooking,
    {
      error: editError,
      isLoading: editLoading,
    }
  ] = useEditBookingMutation()

  const [
    bulkEditBookings,
    {
      error: bulkEditError,
      isLoading: bulkEditLoading,
    }
  ] = useBulkEditBookingsMutation()

  const [checkBookingDates] = useLazyGetBookingsQuery()

  const relatedBookings = useRelatedBookings(bookingId, bookingData, myProperties)

  const [bookingPreview, setBookingPreview] = useState<Partial<any>>({ booking: {} })


  const validateDate = async (selectedPropertyId: string, checkIn: Date, checkOut: Date) => {
    setDateValidationStatus({
      available: false,
      status: 'warning',
      message: 'Checking date range for property'
    })

    const propertyId = [selectedPropertyId]
    if (myProperties[selectedPropertyId]) {
      const {
        multiUnit: {
          isMultiUnit,
          linkedUnits,
        }
      } = myProperties[selectedPropertyId]
      if (isMultiUnit) {
        linkedUnits.forEach((unit: PropertyLinkedPropertyType) => {
          propertyId.push(unit.propertyId)
          // check for parent properties
        })
      }
    }

    const checkedBookingData = await checkBookingDates({
      bookingsQuery: {
        propertyId,
        checkIn,
        checkOut,
        bookingStatus: ['new', 'confirmed', 'pending']
      },
      pagination: {
        page: 1,
        limit: 10
      }
    }).unwrap()

    if (checkedBookingData && checkedBookingData.docs && checkedBookingData.docs.length > 0) {
      const relatedBookingIds: { [b: string]: boolean } = {}
      relatedBookings.forEach(booking => {
        if (booking._id) {
          relatedBookingIds[booking._id] = true
        }
      })
      const blockingBookings = checkedBookingData.docs.filter((b: any) => {
        if (b._id === _id || relatedBookingIds[b._id]) {
          return false
        }
        return true
      })

      if (blockingBookings.length > 0) {
        setDateValidationStatus({
          available: false,
          status: 'danger',
          message: 'WARNING: selected date range is unavailable for this property'
        })
        return
      }
    }
    setDateValidationStatus({
      available: true,
      status: 'success',
      message: 'This property is available for  the date range'
    })
    return
  }

  const handleSubmit = async (data: PropertyBookingFormEntry) => {
    const values: any = {
      ...data
    }
    if (!values.dateBooked) {
      values.dateBooked = new Date()
    }
    delete values.dateRange
    delete values.bookingTotal
    if (_id) {
      // check for related bookings
      if (relatedBookings && relatedBookings.length > 0) {
        // if there are related bookings, check if any are child bookings
        const childBookings = relatedBookings.filter(b => !isParent(myProperties[b.propertyId], myProperties[bookingData.propertyId]))
        if (childBookings.length > 0) {
          const bookingsToEdit = [{ ...values }]
          childBookings.forEach(booking => {
            bookingsToEdit.push({
              ...values,
              _id: booking._id,
              propertyId: booking.propertyId,
              pricePerNight: values.pricePerNight ? values.pricePerNight / childBookings.length : 0,
              requiresConfirmation: booking.requiresConfirmation,
              confirmationCode: booking.confirmationCode,
            })
          })
          const newBookings = await bulkEditBookings(bookingsToEdit).unwrap()
          if (newBookings && !newBookings.error) {
            dispatch(navigateTo(`/bookings?query={"firstName":"${values.contactInfo?.firstName}","lastName":"${values.contactInfo?.lastName}"}`))
          }
          return newBookings
        }
      }

      const editedBooking = await editBooking({ bookingId: _id, bookingInfo: values }).unwrap()
      if (editedBooking._id) {
        dispatch(navigateTo(`/booking/${editedBooking._id}`))
      }
      return editedBooking
    }
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Booking</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {editError && (<>{JSON.stringify(editError)}<br /></>)}
          {bulkEditError && (<>{JSON.stringify(bulkEditError)}<br /></>)}

          {relatedBookings && relatedBookings.length > 0 && (
            <div className="related-bookings">
              <h3>Related Bookings</h3>
              <ul>
                {relatedBookings.map((booking: PropertyBookingEntry) => {
                  const propertyInfo = myProperties[booking.propertyId]
                  const duplicateRelated = booking.propertyId === bookingData.propertyId
                  return (
                    <li key={booking._id}>
                      <Link to={`/edit-booking/${booking._id}`}>
                        {propertyInfo.title}
                      </Link>  {isParent(myProperties[booking.propertyId], myProperties[bookingData.propertyId]) && (
                        <span className="parent-property"> - Parent (edit this to keep changes synced)&nbsp;</span>
                      )}

                      {duplicateRelated && (
                        <Badge bg="danger">DUPLICATE</Badge>
                      )}
                      <div
                        role="button"
                        className="d-inline-block"
                        onClick={() => {
                          setBookingPreview({ booking })
                        }}
                      >
                        👀
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}

          {bookingId && bookingId === _id && bookingData && myProperties && (
            <BookingForm
              disabled={isLoading || editLoading || bulkEditLoading}
              initialValues={bookingData}
              onSubmit={handleSubmit}
              propertyOptionList={propertyOptions}
              dateValidationStatus={dateValidationStatus}
              dateValidationFn={validateDate}
              propertyData={myProperties}
            />
          )}

        </Col>
      </Row>

      <BookingPreview
        booking={bookingPreview.booking}
        onClose={() => {
          setBookingPreview({
            booking: {},
          })
        }}
      />
    </Container>
  )
}
