export default function LoadingSpinner(props: {
    text?: string
}) {

    const {
        text = 'Loading...'
    } = props

    return (
        <p>
            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
            <span className="sr-only">{text}</span>
        </p>
    )

}