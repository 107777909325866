import { useCallback, useEffect } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import HomePage from './Pages/Home/HomePage';
import LoginPage from './Pages/Account/Login/LoginPage';
import { useSelector } from 'react-redux';
import RegisterPage from './Pages/Account/Register/RegisterPage';
import { useAppDispatch } from './redux/store';
import ForgotPasswordPage from './Pages/Account/ForgotPassword/ForgotPasswordPage';
import UsersPage from './Pages/Users/UsersPage';
import UserPage from './Pages/Users/User/UserPage';
import { accountSelector, logoutUser, setUserInfo } from './redux/account/accountSlice';
import AccountPage from './Pages/Account/Account';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import PropertiesPage from './Pages/Properties/PropertiesPage';
import PropertyPage from './Pages/Properties/Property/PropertyPage';
import AddPropertyPage from './Pages/Properties/AddProperty/AddPropertyPage';
import EditPropertyPage from './Pages/Properties/EditProperty/EditPropertyPage';
import MyPropertiesPage from './Pages/Properties/MyProperties/MyPropertiesPage';
import BookingsPage from './Pages/Bookings/BookingsPage';
import BookingPage from './Pages/Bookings/Booking/BookingPage';
import AddBookingPage from './Pages/Bookings/AddBooking/AddBookingPage';
import EditBookingPage from './Pages/Bookings/EditBooking/EditBookingPage';
import DeletePropertyPage from './Pages/Properties/DeleteProperty/DeletePropertyPage';
import DeleteBookingPage from './Pages/Bookings/DeleteBooking/DeleteBookingPage';
import DeleteUserPage from './Pages/Users/DeleteUser/DeleteUserPage';
import EditUserPage from './Pages/Users/EditUser/EditUserPage';
import IcalPage from './Pages/Icals/Ical/IcalPage';
import IcalsPage from './Pages/Icals/IcalsPage';
import UnconfirmedBookingsPage from './Pages/Bookings/UnconfirmedBookings/UnconfirmedBookingsPage';
import UpcomingBookingsPage from './Pages/Bookings/UpcomingBookings/UpcomingBookingsPage';
import CleaningPage from './Pages/Cleaning/CleaningPage';
import DealsPage from './Pages/Deals/DealsPage';
import FinancialsPage from './Pages/Financials/FinancialsPage';
import InventoryCheckerPage from './Pages/InventoryChecker/InventoryCheckerPage';
import BookingMessagesPage from './Pages/BookingMessages/BookingMessagesPage';
import ViewBookingMessagesPage from './Pages/BookingMessages/ViewBookingMessages/ViewBookingMessagesPage';
import PropertyTasksPage from './Pages/PropertyTasks/PropertyTasksPage';
import PropertyTaskPage from './Pages/PropertyTasks/PropertyTask/PropertyTaskPage';
import AddPropertyTaskPage from './Pages/PropertyTasks/AddPropertyTask/AddPropertyTaskPage';
import DeletePropertyTaskPage from './Pages/PropertyTasks/DeletePropertyTask/DeletePropertyTaskPage';
import EditPropertyTaskPage from './Pages/PropertyTasks/EditPropertyTask/EditPropertyTaskPage';
import PropertyTaskListPage from './Pages/PropertyTasks/ProprtyTaskList/PropertyTaskListPage';
import PropertyTaskReporterPage from './Pages/PropertyTasks/PropertyTaskReporter/PropertyTaskReporterPage';
import AdrOccupancyReporterPage from './Pages/AdrOccupancyReporter/AdrOccupancyReporterPage';
import ExportPropertiesPage from './Pages/Properties/ExportProperties/ExportPropertiesPage';
import ExportBookingsPage from './Pages/Bookings/ExportBookings/ExportBookingsPage';
import ImportPropertiesPage from './Pages/Properties/ImportProperties/ImportPropertiesPage';
import ImportBookingsPage from './Pages/Bookings/ImportBookings/ImportBookingsPage';
import BookingExportCombinerPage from './Pages/Bookings/BookingExportCombiner/BookingExportCombinerPage';
import BukinsPage from './Pages/Bukins/BukinsPage';
import BukinPage from './Pages/Bukins/Bukin/BukinPage';
import AddBukinPage from './Pages/Bukins/AddBukin/AddBukinPage';
import EditBukinPage from './Pages/Bukins/EditBukin/EditBukinPage';
import DeleteBukinPage from './Pages/Bukins/DeleteBukin/DeleteBukinPage';
import BukiblesPage from './Pages/Bukibles/BukiblesPage';
import BukiblePage from './Pages/Bukibles/Bukible/BukiblePage';
import AddBukiblePage from './Pages/Bukibles/AddBukible/AddBukiblePage';
import EditBukiblePage from './Pages/Bukibles/EditBukible/EditBukiblePage';
import DeleteBukiblePage from './Pages/Bukibles/DeleteBukible/DeleteBukiblePage';
import PullToRefresh from 'react-simple-pull-to-refresh';
import BookingRequestsPage from './Pages/BookingRequests/BookingRequestsPage';
import BookingRequestPage from './Pages/BookingRequests/BookingRequest/BookingRequestPage';
import AddBookingRequestPage from './Pages/BookingRequests/AddBookingRequest/AddBookingRequestPage';
import EditBookingRequestPage from './Pages/BookingRequests/EditBookingRequest/EditBookingRequestPage';
import DeleteBookingRequestPage from './Pages/BookingRequests/DeleteBookingRequest/DeleteBookingRequestPage';
import MediaFilesPage from './Pages/MediaFiles/MediaFilesPage';
import MediaFilePage from './Pages/MediaFiles/MediaFile/MediaFilePage';
import AddMediaFilePage from './Pages/MediaFiles/AddMediaFile/AddMediaFilePage';
import EditMediaFilePage from './Pages/MediaFiles/EditMediaFile/EditMediaFilePage';
import DeleteMediaFilePage from './Pages/MediaFiles/DeleteMediaFile/DeleteMediaFilePage';
import InquiriesPage from './Pages/Inquiries/InquiriesPage';
import InquiryPage from './Pages/Inquiries/Inquiry/InquiryPage';
import AddInquiryPage from './Pages/Inquiries/AddInquiry/AddInquiryPage';
import EditInquiryPage from './Pages/Inquiries/EditInquiry/EditInquiryPage';
import DeleteInquiryPage from './Pages/Inquiries/DeleteInquiry/DeleteInquiryPage';
import QueryWhore from './Pages/QueryWhore/QueryWhore';
import DeleteBookingGuestPage from './Pages/BookingGuests/DeleteBookingGuest/DeleteBookingGuestPage';
import EditBookingGuestPage from './Pages/BookingGuests/EditBookingGuest/EditBookingGuestPage';
import AddBookingGuestPage from './Pages/BookingGuests/AddBookingGuest/AddBookingGuestPage';
import BookingGuestPage from './Pages/BookingGuests/BookingGuest/BookingGuestPage';
import BookingGuestsPage from './Pages/BookingGuests/BookingGuestsPage';
import BookingGuestConfirmationPage from './Pages/BookingGuests/BookingGuestConfirmation/BookingGuestConfirmationPage';
import BuildingsPage from './Pages/Buildings/BuildingsPage';
import BuildingPage from './Pages/Buildings/Building/BuildingPage';
import AddBuildingPage from './Pages/Buildings/AddBuilding/AddBuildingPage';
import EditBuildingPage from './Pages/Buildings/EditBuilding/EditBuildingPage';
import DeleteBuildingPage from './Pages/Buildings/DeleteBuilding/DeleteBuildingPage';
import canAccess from './services/accessService/accessService';
import Initializing from './Components/Initializing/Initializing';
import PurchasesPage from './Pages/Purchases/PurchasesPage';
import PurchasePage from './Pages/Purchases/Purchase/PurchasePage';
import AddPurchasePage from './Pages/Purchases/AddPurchase/AddPurchasePage';
import EditPurchasePage from './Pages/Purchases/EditPurchase/EditPurchasePage';
import DeletePurchasePage from './Pages/Purchases/DeletePurchase/DeletePurchasePage';
import PropertyPricesPage from './Pages/PropertyPrices/PropertyPricesPage';
import PropertyPricePage from './Pages/PropertyPrices/PropertyPrice/PropertyPricePage';
import AddPropertyPricePage from './Pages/PropertyPrices/AddPropertyPrice/AddPropertyPricePage';
import EditPropertyPricePage from './Pages/PropertyPrices/EditPropertyPrice/EditPropertyPricePage';
import DeletePropertyPricePage from './Pages/PropertyPrices/DeletePropertyPrice/DeletePropertyPricePage';
import HandlePropertyPricesPage from './Pages/PropertyPrices/HandlePropertyPrices/HandlePropertyPricesPage';
import ImportPropertyPricesPage from './Pages/PropertyPrices/ImportPropertyPricing/ImportPropertyPricesPage';
import RankuuPage from './Pages/Rankuu/Rankuu';
import DateBlocksPage from './Pages/DateBlocks/DateBlocksPage';
import DateBlockPage from './Pages/DateBlocks/DateBlock/DateBlockPage';
import AddDateBlockPage from './Pages/DateBlocks/AddDateBlock/AddDateBlockPage';
import EditDateBlockPage from './Pages/DateBlocks/EditDateBlock/EditDateBlockPage';
import DeleteDateBlockPage from './Pages/DateBlocks/DeleteDateBlock/DeleteDateBlockPage';
import QuickMessage from './Components/QuickMessage/QuickMessage';
import { bClientApi, useLazyCheckUserCredentialsQuery } from './services/bClientApi';
import { getTokenFlow } from './services/authentication/authenticationService';

// let firstLoad = true;

function App() {

  const [
    checkUserCredentials,
  ] = useLazyCheckUserCredentialsQuery()

  const baseUserInfo = useSelector(accountSelector)
  const { token, initialized, firstLoad } = baseUserInfo
  const dispatch = useAppDispatch()

  const handleInitialTokenValidation = useCallback(async () => {
    if (!firstLoad) {
      return;
    }
    // firstLoad = false;
    const storedToken = await getTokenFlow();
    dispatch(setUserInfo({ ...baseUserInfo, token: storedToken, initialized: true, firstLoad: false }));
    if (storedToken) {
      const userInfo = await checkUserCredentials().unwrap()
      if (userInfo && userInfo.email) {
        dispatch(
          setUserInfo({
            ...userInfo,
            token: storedToken,
            initialized: true,
          })
        );
      }
    }
  }, [dispatch, checkUserCredentials, baseUserInfo]);

  useEffect(() => {
    if (firstLoad && !initialized) {
      handleInitialTokenValidation();
    }
    return () => {};
  }, [initialized, handleInitialTokenValidation]);

  const handleRefresh = async () => {
    dispatch(bClientApi.util.resetApiState())
  }

  return (
    <div className="app-container">
      <Header
        loggedIn={!!token}
        logOutFn={async () => {
          dispatch(logoutUser())
          handleRefresh()
        }}
      />

      {/* <hr /> */}
      <PullToRefresh backgroundColor='#eee' onRefresh={handleRefresh}>
        <div className="pull-bar" />
      </PullToRefresh>

      <div className="app-content">
        {!initialized  ? (
          <Initializing />
        ) : !token ? (
          <Routes>
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="*" element={<LoginPage />} />
          </Routes>
        ) : (
          <Routes>

            {pageRoutes.map((routeInfo, idx) => {
              if (canAccess('routes', routeInfo.action)) {
                return (
                  <Route path={routeInfo.link} element={routeInfo.component} key={idx} />
                )
              }
              return null
            })}

            <Route path="*" element={<HomePage />} />

          </Routes>
        )}
      </div>


      <QuickMessage />
      <hr />

      <Footer />

    </div>
  );
}

const pageRoutes = [

  { link: '/property-task-list', component: <PropertyTaskListPage />, action: 'property-task-list' },
  { link: '/cleaning', component: <CleaningPage />, action: 'cleaning' },
  { link: '/confirm-booking-guests/', component: <BookingGuestConfirmationPage />, action: 'confirm-booking-guests' },
  { link: '/inventory-check/:propertyId', component: <InventoryCheckerPage />, action: 'inventory-check' },
  { link: '/account', component: <AccountPage />, action: 'account' },
  { link: '/login', component: <LoginPage />, action: 'login' },
  { link: '/register', component: <RegisterPage />, action: 'register' },
  { link: '/forgot-password', component: <ForgotPasswordPage />, action: 'forgot-password' },

  { link: '/my-properties', component: <MyPropertiesPage />, action: 'my-properties' },
  { link: '/property/ical-data/:propertyId', component: <IcalPage />, action: 'ical-data' },
  { link: '/all-icals', component: <IcalsPage />, action: 'all-icals' },

  { link: '/bookings', component: <BookingsPage />, action: 'bookings' },
  { link: '/booking/:bookingId', component: <BookingPage />, action: 'booking' },
  { link: '/add-booking', component: <AddBookingPage />, action: 'add-booking' },
  { link: '/edit-booking/:bookingId', component: <EditBookingPage />, action: 'edit-booking' },
  { link: '/delete-booking/:bookingId', component: <DeleteBookingPage />, action: 'delete-booking' },
  { link: '/export-bookings', component: <ExportBookingsPage />, action: 'export-bookings' },
  { link: '/import-bookings', component: <ImportBookingsPage />, action: 'import-bookings' },
  { link: '/booking-export-combiner', component: <BookingExportCombinerPage />, action: 'booking-export-combiner' },

  { link: '/booking-guests', component: <BookingGuestsPage />, action: 'booking-guests' },
  { link: '/booking-guest/:bookingGuestId', component: <BookingGuestPage />, action: 'booking-guest' },
  { link: '/add-booking-guest', component: <AddBookingGuestPage />, action: 'add-booking-guest' },
  { link: '/edit-booking-guest/:bookingGuestId', component: <EditBookingGuestPage />, action: 'edit-booking-guest' },
  { link: '/delete-booking-guest/:bookingGuestId', component: <DeleteBookingGuestPage />, action: 'delete-booking-guest' },

  { link: '/booking-messages', component: <BookingMessagesPage />, action: 'booking-messages' },
  { link: '/view-booking-messages/:bookingId', component: <ViewBookingMessagesPage />, action: 'view-booking-messages' },

  { link: '/unconfirmed-bookings', component: <UnconfirmedBookingsPage />, action: 'unconfirmed-bookings' },
  { link: '/upcoming-bookings', component: <UpcomingBookingsPage />, action: 'upcoming-bookings' },

  { link: '/booking-requests', component: <BookingRequestsPage />, action: 'booking-requests' },
  { link: '/booking-request/:bookingRequestId', component: <BookingRequestPage />, action: 'booking-request' },
  { link: '/add-booking-request', component: <AddBookingRequestPage />, action: 'add-booking-request' },
  { link: '/edit-booking-request/:bookingRequestId', component: <EditBookingRequestPage />, action: 'edit-booking-request' },
  { link: '/delete-booking-request/:bookingRequestId', component: <DeleteBookingRequestPage />, action: 'delete-booking-request' },

  { link: '/properties', component: <PropertiesPage />, action: 'properties' },
  { link: '/property/:propertyId', component: <PropertyPage />, action: 'property' },
  { link: '/add-property', component: <AddPropertyPage />, action: 'add-property' },
  { link: '/edit-property/:propertyId', component: <EditPropertyPage />, action: 'edit-property' },
  { link: '/delete-property/:propertyId', component: <DeletePropertyPage />, action: 'delete-property' },
  { link: '/export-properties', component: <ExportPropertiesPage />, action: 'export-properties' },
  { link: '/import-properties', component: <ImportPropertiesPage />, action: 'import-properties' },

  { link: '/financials-property/:propertyId', component: <FinancialsPage />, action: 'financials-property' },

  { link: '/property-task-reporter', component: <PropertyTaskReporterPage />, action: 'property-task-reporter' },
  { link: '/property-tasks', component: <PropertyTasksPage />, action: 'property-tasks' },
  { link: '/property-task/:propertyTaskId', component: <PropertyTaskPage />, action: 'property-task' },
  { link: '/add-property-task', component: <AddPropertyTaskPage />, action: 'add-property-task' },
  { link: '/edit-property-task/:propertyTaskId', component: <EditPropertyTaskPage />, action: 'edit-property-task' },
  { link: '/delete-property-task/:propertyTaskId', component: <DeletePropertyTaskPage />, action: 'delete-property-task' },

  { link: '/adr-occupancy-reporter', component: <AdrOccupancyReporterPage />, action: 'adr-occupancy-reporter' },
  { link: '/deal-tool', component: <DealsPage />, action: 'deal-tool' },

  { link: '/users', component: <UsersPage />, action: 'users' },
  { link: '/user/:userId', component: <UserPage />, action: 'user' },
  { link: '/edit-user/:userId', component: <EditUserPage />, action: 'edit-user' },
  { link: '/delete-user/:userId', component: <DeleteUserPage />, action: 'delete-user' },

  { link: '/bukins', component: <BukinsPage />, action: 'bukins' },
  { link: '/bukin/:bukinId', component: <BukinPage />, action: 'bukin' },
  { link: '/add-bukin', component: <AddBukinPage />, action: 'add-bukin' },
  { link: '/edit-bukin/:bukinId', component: <EditBukinPage />, action: 'edit-bukin' },
  { link: '/delete-bukin/:bukinId', component: <DeleteBukinPage />, action: 'delete-bukin' },

  { link: '/bukibles', component: <BukiblesPage />, action: 'bukibles' },
  { link: '/bukible/:bukibleId', component: <BukiblePage />, action: 'bukible' },
  { link: '/add-bukible', component: <AddBukiblePage />, action: 'add-bukible' },
  { link: '/edit-bukible/:bukibleId', component: <EditBukiblePage />, action: 'edit-bukible' },
  { link: '/delete-bukible/:bukibleId', component: <DeleteBukiblePage />, action: 'delete-bukible' },

  { link: '/mediaFiles', component: <MediaFilesPage />, action: 'mediaFiles' },
  { link: '/mediaFile/:mediaFileId', component: <MediaFilePage />, action: 'mediaFile' },
  { link: '/add-mediaFile', component: <AddMediaFilePage />, action: 'add-mediaFile' },
  { link: '/edit-mediaFile/:mediaFileId', component: <EditMediaFilePage />, action: 'edit-mediaFile' },
  { link: '/delete-mediaFile/:mediaFileId', component: <DeleteMediaFilePage />, action: 'delete-mediaFile' },

  { link: '/inquiries', component: <InquiriesPage />, action: 'inquiries' },
  { link: '/inquiry/:inquiryId', component: <InquiryPage />, action: 'inquiry' },
  { link: '/add-inquiry', component: <AddInquiryPage />, action: 'add-inquiry' },
  { link: '/edit-inquiry/:inquiryId', component: <EditInquiryPage />, action: 'edit-inquiry' },
  { link: '/delete-inquiry/:inquiryId', component: <DeleteInquiryPage />, action: 'delete-inquiry' },

  { link: '/buildings', component: <BuildingsPage />, action: 'buildings' },
  { link: '/building/:buildingId', component: <BuildingPage />, action: 'building' },
  { link: '/add-building', component: <AddBuildingPage />, action: 'add-building' },
  { link: '/edit-building/:buildingId', component: <EditBuildingPage />, action: 'edit-building' },
  { link: '/delete-building/:buildingId', component: <DeleteBuildingPage />, action: 'delete-building' },

  // purchases
  { link: '/purchases', component: <PurchasesPage />, action: 'purchases' },
  { link: '/purchase/:purchaseId', component: <PurchasePage />, action: 'purchase' },
  { link: '/add-purchase', component: <AddPurchasePage />, action: 'add-purchase' },
  { link: '/edit-purchase/:purchaseId', component: <EditPurchasePage />, action: 'edit-purchase' },
  { link: '/delete-purchase/:purchaseId', component: <DeletePurchasePage />, action: 'delete-purchase' },

  // property prices
  { link: '/property-prices', component: <PropertyPricesPage />, action: 'property-prices' },
  { link: '/property-price/:propertyPriceId', component: <PropertyPricePage />, action: 'property-price' },
  { link: '/add-property-price', component: <AddPropertyPricePage />, action: 'add-property-price' },
  { link: '/edit-property-price/:propertyPriceId', component: <EditPropertyPricePage />, action: 'edit-property-price' },
  { link: '/delete-property-price/:propertyPriceId', component: <DeletePropertyPricePage />, action: 'delete-property-price' },
  { link: '/handle-property-prices/:propertyId', component: <HandlePropertyPricesPage />, action: 'handle-property-prices' },
  { link: '/import-property-prices/:propertyId', component: <ImportPropertyPricesPage />, action: 'import-property-prices' },

  // date blocks
  { link: '/date-blocks', component: <DateBlocksPage />, action: 'date-blocks' },
  { link: '/date-block/:dateBlockId', component: <DateBlockPage />, action: 'date-block' },
  { link: '/add-date-block', component: <AddDateBlockPage />, action: 'add-date-block' },
  { link: '/edit-date-block/:dateBlockId', component: <EditDateBlockPage />, action: 'edit-date-block' },
  { link: '/delete-date-block/:dateBlockId', component: <DeleteDateBlockPage />, action: 'delete-date-block' },

  { link: '/query-whore', component: <QueryWhore />, action: 'query-whore' },

  { link: '/rankuu', component: <RankuuPage />, action: 'rankuu' }
];

export default App;
