import { Button, Col, Container, Row } from "react-bootstrap";
import timeService from "../../../services/time/timeService";
import { useMemo, useState } from "react";
import './handlePropertyPrices.scss'
import QuickForm from "../../../Components/Forms/QuickForm/QuickForm";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import { useAppDispatch } from "../../../redux/store";
import { Link, useParams } from "react-router-dom";
import Balendar from "../../../Components/Balendar/Balendar";
import { PropertyBookingEntry } from "../../../Types/bookingTypes";
import canAccess from "../../../services/accessService/accessService";
import BookingPreview from "../../Bookings/Booking/BookingPreview";
import { showSuccess } from "../../../redux/counter/counterSlice";
import { useBulkEditPropertyPricesMutation, useDeletePropertyPriceMutation, useGetBookingsQuery, useGetPropertyPricesQuery, useImportPropertyPricesMutation } from "../../../services/bClientApi";

const DATE_FORMAT = 'yyyy/MM/dd'

export default function HandlePropertyPricesPage() {

    const [deletePropertyPrice] = useDeletePropertyPriceMutation()
    const [importPropertyPrices] = useImportPropertyPricesMutation()
    const [bulkEditPropertyPrices] = useBulkEditPropertyPricesMutation()

    const dispatch = useAppDispatch()

    const { propertyId } = useParams()
    const selectedPropertyId = propertyId || '1234567890'
    const { myProperties } = useMyProperties()
    const propertyData = myProperties[selectedPropertyId] || {}

    const [bookingPreview, setBookingPreview] = useState<Partial<any>>({ booking: {} })

    const [deletingDuplicatePrices, setDeletingDuplicatePrices] = useState(false)
    const [editingPrices, setEditingPrices] = useState(false)

    const [selectedStartDate, setSelectedStartDate] = useState<string | Date | null>(null)
    const [selectedEndDate, setSelectedEndDate] = useState<string | Date | null>(null)
    const [hoveredDate, setHoveredDate] = useState<string | Date | null>(null)

    const [formPrice, setFormPrice] = useState(0)

    const [activeDay, setActiveDay] = useState(new Date())

    const selectedStartDateMillis = timeService.dateToMillis(selectedStartDate ? new Date(selectedStartDate) : activeDay)
    const selectedEndDateMillis = timeService.dateToMillis(selectedEndDate ? new Date(selectedEndDate) : activeDay)

    const monthOffset = 1
    const startOfMonth = timeService.createLuxonDate(activeDay).startOf('month').startOf('day')
    const endOfMonth = timeService.createLuxonDate(activeDay).plus({ months: monthOffset }).endOf('month').endOf('day')

    const activeStart = startOfMonth.toMillis() < selectedStartDateMillis ? startOfMonth.toMillis() : selectedStartDateMillis
    const activeEnd = endOfMonth.toMillis() > selectedEndDateMillis ? endOfMonth.toMillis() : selectedEndDateMillis

    const activeQuery = {
        query: {
            propertyId: selectedPropertyId,
            dateStart: activeStart,
            dateEnd: activeEnd,
        },
        page: 1,
        limit: 500,
        all: true,
    }

    const {
        data: propertyPricesData,
    } = useGetPropertyPricesQuery({
        propertyPricesQuery: {
            ...activeQuery.query,
        },
        pagination: {
            page: activeQuery.page,
            limit: activeQuery.limit,
        }
    }, { skip: Object.keys(myProperties).length === 0 })
    // const propertyPricesList = propertyPricesData?.docs || []

    const activeBookingQuery = {
        query: {
            propertyId: selectedPropertyId,
            checkIn: activeStart,
            checkOut: activeEnd,
            bookingStatus: ['new', 'pending', 'confirmed', 'request']
        },
        page: 1,
        limit: 500,
        all: true,
    }

    const {
        data,
    } = useGetBookingsQuery({
        bookingsQuery: activeBookingQuery.query,
        pagination: {
            limit: activeBookingQuery.limit,
            page: activeBookingQuery.page
        }
    }, { skip: Object.keys(myProperties).length === 0 })

    // const bookingsList = data?.docs || []

    const propertyBookingRef: {
        [k: string]: {
            bookingInfo: any,
            checkingIn: string,
            checkingOut: string,
        }
    } = useMemo(() => {
        const bookingsList = data?.docs || []
        if (!bookingsList || bookingsList === null || bookingsList.length === 0) return {}
        const tempBookingRef: any = {};
        ([...bookingsList]).sort((j: any, k: any) => {
            return timeService.dateToMillis(j.checkIn) - timeService.dateToMillis(k.checkIn)
        }).forEach((b: PropertyBookingEntry) => {
            let bookingStartDate = timeService.createLuxonDate(b.checkIn).minus({ days: 1 })
            const bookingStartString = timeService.getFormattedTime(b.checkIn, DATE_FORMAT)
            const bookingEndDateString = timeService.getFormattedTime(b.checkOut, DATE_FORMAT)
            const bookingEndUnix = timeService.dateToMillis(b.checkOut)
            while (bookingStartDate.toFormat(DATE_FORMAT) !== bookingEndDateString && bookingStartDate.toMillis() < bookingEndUnix) {
                bookingStartDate = bookingStartDate.plus({ days: 1 })
                const currentDayString = bookingStartDate.toFormat(DATE_FORMAT)
                if (!tempBookingRef[currentDayString]) {
                    tempBookingRef[currentDayString] = {
                        bookingInfo: {},
                        checkingIn: '',
                        checkingOut: '',
                    }
                }
                tempBookingRef[currentDayString].bookingInfo = {
                    checkIn: bookingStartString,
                    checkOut: bookingEndDateString,
                    name: `${b.contactInfo?.firstName} ${b.contactInfo?.lastName}`,
                    raw: { ...b }
                }
                if (currentDayString === bookingStartString) {
                    // tempBookingRef[currentDayString].checkingIn = bookingStartString
                    tempBookingRef[currentDayString].checkingIn = `${b.contactInfo?.firstName} ${b.contactInfo?.lastName}`
                }
                if (currentDayString === bookingEndDateString) {
                    // tempBookingRef[currentDayString].checkingOut = bookingEndDateString
                    tempBookingRef[currentDayString].checkingOut = `${b.contactInfo?.firstName} ${b.contactInfo?.lastName}`
                }
            }
        })
        return tempBookingRef
    }, [data])

    const propertyPriceRef: any = useMemo(() => {
        const propertyPricesList = propertyPricesData?.docs || []
        const tempPriceRef: any = {}
        propertyPricesList?.forEach((x: any) => {
            const date = timeService.getFormattedTime(x.date, DATE_FORMAT)
            tempPriceRef[date] = x
        })
        return tempPriceRef
    }, [propertyPricesData])

    const handleDateSelection = (date: string | Date | null) => {
        if (!date) {
            setSelectedStartDate(null)
            setSelectedEndDate(null)
            return
        }
        if (selectedStartDate && selectedEndDate) {
            setSelectedStartDate(date)
            setSelectedEndDate(null)
            return
        }
        if (!selectedStartDate && !selectedEndDate) {
            setSelectedStartDate(date)
            setSelectedEndDate(null)
            return
        }
        const dateMillis = timeService.dateToMillis(date)
        if (selectedStartDate && !selectedEndDate) {
            if (dateMillis < timeService.dateToMillis(selectedStartDate)) {
                setSelectedStartDate(date)
                setSelectedEndDate(null)
                return
            }
            setSelectedEndDate(date)
            return
        }
    }

    const duplicatePropertyPrices: any = useMemo(() => {
        const propertyPricesList = propertyPricesData?.docs || []
        const dupePrices = propertyPricesList?.filter((x: any) => {
            const date = timeService.getFormattedTime(x.date, DATE_FORMAT)
            return propertyPriceRef[date] && propertyPriceRef[date]._id !== x._id
        })
        return dupePrices
    }, [propertyPricesData, propertyPriceRef])

    const handleSubmit = async (values: any) => {
        if (!selectedStartDate || !selectedEndDate) { return }
        setEditingPrices(true)
        const newPrices: any[] = []
        const updatePrices: any[] = []

        const tempDateArray: string[] = []
        let safety = 0
        let from = timeService.createLuxonDate(selectedStartDate).startOf('day');
        const to = timeService.createLuxonDate(selectedEndDate).endOf('day').toMillis();
        while (safety < 1000 && from.toMillis() < to) {
            const tempDayKey = from.toFormat(DATE_FORMAT)
            tempDateArray.push(tempDayKey)
            from = from.plus({ days: 1 })
            safety++
        }

        tempDateArray.forEach((dayKey: string) => {
            const pricePayload = {
                propertyId: selectedPropertyId,
                date: timeService.setDateTime(new Date(dayKey), { hour: 16 }),
                price: values.price
            }
            if (propertyPriceRef[dayKey]) {
                updatePrices.push({
                    _id: propertyPriceRef[dayKey]._id,
                    ...pricePayload
                })
            } else {
                newPrices.push(pricePayload)
            }
        })

        if (newPrices.length > 0) {
            const newCreatedPrices = await importPropertyPrices(newPrices).unwrap()
            console.log('new', newCreatedPrices)
        }

        if (updatePrices.length > 0) {
            const updatedPrices = await bulkEditPropertyPrices(updatePrices).unwrap()
            console.log('updated', updatedPrices)
        }

        setEditingPrices(false)

        dispatch(showSuccess('Prices Updated'))

    }

    const openBookingPreview = (booking: any) => {
        setBookingPreview({
            booking,
        })
    }

    const deleteDuplicatePrices = async () => {
        setDeletingDuplicatePrices(true)
        for (let i = 0; i < duplicatePropertyPrices.length; i++) {
            const x = duplicatePropertyPrices[i]
            await deletePropertyPrice(x._id).unwrap()
        }
        setDeletingDuplicatePrices(false)
        dispatch(showSuccess('Deleted Duplicate Prices'))
    }

    const tempDateRange = useMemo(() => {
        const tempDateArray: string[] = []

        if (!selectedStartDate || !selectedEndDate) return tempDateArray

        let safety = 0
        let from = timeService.createLuxonDate(selectedStartDate).startOf('day');
        const to = timeService.createLuxonDate(selectedEndDate).endOf('day').toMillis();
        while (safety < 1000 && from.toMillis() < to) {
            const tempDayKey = from.toFormat(DATE_FORMAT)
            tempDateArray.push(tempDayKey)
            from = from.plus({ days: 1 })
            safety++
        }

        return tempDateArray

    }, [selectedStartDate, selectedEndDate])

    let bookingPrice = 0

    tempDateRange.forEach((x: string) => {
        if (formPrice > 0) {
            bookingPrice += formPrice
            return
        }
        if (propertyPriceRef[x]) {
            bookingPrice += propertyPriceRef[x].price
        }
    })

    const taxRate = (propertyData?.pricing?.tax || 14) / 100;
    const taxPrice = Number(bookingPrice * taxRate).toFixed(2)
    const cleaningPrice = propertyData?.pricing?.cleaning || 0
    const totalBookingPrice = Number((bookingPrice + cleaningPrice) * (1 + taxRate)).toFixed(2)
    const averagePrice = Number(bookingPrice / tempDateRange.length).toFixed(2)

    return (
        <Container>
            <Row className="mb-3">
                <Col>
                    <h1>HandlePropertyPrice</h1>
                    <hr />
                    Property Title : {propertyData?.title}

                    <div className="w-full my-2 small fst-italic">
                        NOTE: Prices are updated automatically every 8 hours based on price labs
                    </div>
                </Col>
            </Row>

            {duplicatePropertyPrices && duplicatePropertyPrices.length > 0 && (
                <Row>
                    <Col>
                        <div className="duplicate-prices">
                            <h3>Duplicate Prices Detected</h3>
                            {deletingDuplicatePrices && (
                                <div>Deleting...</div>
                            )}
                            <Button
                                variant="danger"
                                onClick={() => {
                                    deleteDuplicatePrices()
                                }}
                                disabled={deletingDuplicatePrices}
                            >
                                Click Here to Delete them
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}


            <Row>
                <Col>
                    <Balendar
                        selectedEndDate={selectedEndDate}
                        selectedStartDate={selectedStartDate}
                        onSelectDate={handleDateSelection}
                        hoveredDate={hoveredDate}
                        activeDay={activeDay}
                        onDateChange={(date: Date) => {
                            setActiveDay(date)
                        }}
                        onCancel={() => {
                            setSelectedStartDate(null)
                            setSelectedEndDate(null)
                        }}
                        onHoverDate={(date: string | Date | null) => {
                            setHoveredDate(date)
                        }}
                        dayRender={(dayInfo: any) => {
                            const { dayKey } = dayInfo
                            const x = propertyPriceRef[dayKey]
                            const b = propertyBookingRef[dayKey]
                            const rawBookingInfo = b?.bookingInfo?.raw || {}
                            return (
                                <>
                                    <div className="price-info">
                                        {x && x.price ? `$${x.price}` : '-'}
                                    </div>

                                    {b && b.bookingInfo && (
                                        <div
                                            className={`booking-info${rawBookingInfo.bookingStatus === 'request' ? ' booking-request' : ''}`}
                                            onClick={(e: any) => {
                                                e.stopPropagation()
                                                console.log(rawBookingInfo)
                                                openBookingPreview({ ...rawBookingInfo })
                                            }}
                                        >
                                            {!b.checkingIn && !b.checkingOut && (
                                                <div className="full-day booking-line">
                                                    {b.bookingInfo.name}
                                                </div>
                                            )}
                                            {b.checkingOut && (
                                                <div className="check-out-day booking-line">
                                                    {b.checkingOut}
                                                </div>
                                            )}
                                            {b.checkingIn && (
                                                <div className="check-in-day booking-line">
                                                    {b.checkingIn}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )
                        }}
                    />
                </Col>

                <Col>

                    <Balendar
                        selectedEndDate={selectedEndDate}
                        selectedStartDate={selectedStartDate}
                        onSelectDate={handleDateSelection}
                        hoveredDate={hoveredDate}
                        activeDay={activeDay}
                        monthOffset={1}
                        onDateChange={(date: Date) => {
                            setActiveDay(date)
                        }}
                        onCancel={() => {
                            setSelectedStartDate(null)
                            setSelectedEndDate(null)
                        }}
                        onHoverDate={(date: string | Date | null) => {
                            setHoveredDate(date)
                        }}
                        dayRender={(dayInfo: any) => {
                            const { dayKey } = dayInfo
                            const x = propertyPriceRef[dayKey]
                            const b = propertyBookingRef[dayKey]
                            const rawBookingInfo = b?.bookingInfo?.raw || {}
                            return (
                                <>
                                    <div className="price-info">
                                        {x && x.price ? `$${x.price}` : '-'}
                                    </div>

                                    {b && b.bookingInfo && (
                                        <div
                                            className={`booking-info${rawBookingInfo.bookingStatus === 'request' ? ' booking-request' : ''}`}
                                            onClick={(e: any) => {
                                                e.stopPropagation()
                                                console.log(rawBookingInfo)
                                                openBookingPreview({ ...rawBookingInfo })

                                            }}
                                        >
                                            {!b.checkingIn && !b.checkingOut && (
                                                <div className="full-day booking-line">
                                                    {b.bookingInfo.name}
                                                </div>
                                            )}
                                            {b.checkingOut && (
                                                <div className={`check-out-day booking-line`}>
                                                    {b.checkingOut}
                                                </div>
                                            )}
                                            {b.checkingIn && (
                                                <div className={`check-in-day booking-line`}>
                                                    {b.checkingIn}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </>
                            )
                        }}
                    />
                </Col>
            </Row>

            {canAccess('routes', 'edit-property-prices') && (
                <Row>
                    <Col>
                        <div className="set-pricing-container">
                            {/* <div className="set-pricing">
                                Set Pricing
                            </div>
                            <div className="selected-dates mb-2">
                                {selectedStartDate && (
                                    <div className="selected-start">
                                        {selectedStartDate}
                                    </div>
                                )}
                                {selectedEndDate && selectedStartDate !== selectedEndDate && (
                                    <div className="selected-end">
                                        {selectedEndDate}
                                    </div>
                                )}
                            </div> */}

                            {tempDateRange && tempDateRange.length > 0 && (
                                <div className="date-range-list mb-4">
                                    <div>
                                        Date Range Selected ({tempDateRange.length} days)
                                    </div>
                                    <hr />
                                    Average Price per night: ${averagePrice}
                                    <hr />
                                    {tempDateRange && tempDateRange.map((x: string, idx: number) => (
                                        <div key={`${x}-${formPrice}`} className="date-range-item">
                                            {x} - ${formPrice > 0 ? formPrice : propertyPriceRef[x] ? `${propertyPriceRef[x].price}` : '-'}
                                        </div>
                                    ))}
                                    <hr />
                                    Total Booking Price: ${bookingPrice} <br />
                                    Cleaning: ${cleaningPrice} <br />
                                    Tax: ${taxPrice} ({(taxRate * 100).toFixed(2)}%) <br />
                                    <hr />
                                    Estimated Total: ${totalBookingPrice}

                                </div>
                            )}

                            <div className="pricing-input">
                                <QuickForm
                                    initialData={{
                                        price: 0,
                                    }}
                                    fields={[
                                        {
                                            fieldName: 'price',
                                            fieldLabel: 'Price',
                                            fieldType: 'number',
                                            placeholder: 'Price',
                                            onChange: (e: any) => {
                                                setFormPrice(Number(e.target.value))
                                            },
                                            extra: {
                                                preText: '$'
                                            }
                                        }
                                    ]}
                                    onCancel={() => {
                                        setSelectedStartDate(null)
                                        setSelectedEndDate(null)
                                    }}
                                    // onChange={(values: any, api: any) => {
                                    //     console.log(values.target)
                                    //     // setFormPrice(values.price)
                                    // }}
                                    disabled={!selectedStartDate || !selectedEndDate || editingPrices || deletingDuplicatePrices}
                                    onSubmit={(values: any) => {
                                        handleSubmit(values)
                                    }}
                                />
                            </div>
                            {canAccess('routes', 'import-property-prices') && (
                                <Link to={`/import-property-prices/${selectedPropertyId}`}>
                                    Bulk Import Prices
                                </Link>
                            )}

                        </div>

                    </Col>
                </Row>
            )}

            <BookingPreview
                booking={bookingPreview.booking}
                onClose={() => {
                    setBookingPreview({
                        booking: {}
                    })
                }}
            />
        </Container>
    )
}
